import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Image, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../../Redux/Slices/Education/Group/asyncThunk";
import { Row, rowSelection } from "../Common/tableUtils"
import { setModalTransferData, setModalType } from '../../Redux/Slices/modal';
import Connections from "../../Services/connections";


const Groups = () => {
    const dispatch = useDispatch()
    const groups = useSelector( state => state.groups.all )

    useEffect( () => {
        dispatch( getGroups() )
    }, [ dispatch ] )


    const lessonsColumns = [
        {
            key: "Lesson",
            title: 'Lesson',
            render: ( record ) => {
                return record?.name || <b><i>Empty</i></b>
            }
        },
        {
            key: "Icon",
            title: 'Icon',
            dataIndex: "icon",
            render: ( icon ) => {
                return <Image
                    src={ Connections.GetImage( icon ) }
                    width="35px"
                    height="35px"
                    alt="avatar"
                    style={ { width: '100%' } }
                />
            }
        },
        {
            key: "Photo",
            title: 'Photo',
            dataIndex: "photo",
            render: ( photo ) => {
                return <Image
                    src={ Connections.GetImage( photo ) }
                    width="55px"
                    height="35px"
                    alt="avatar"
                    style={ { width: '100%' } }
                />
            }
        }
    ]
    const expandableSubLessonsColumns = [
        {
            key: "8",
            title: 'ID',
            render: ( record ) => {
                return record?.id || <b><i>Empty</i></b>
            }
        },
        {
            key: "9",
            title: 'Title',
            dataIndex: "title",
            render: ( title ) => {
                return title.en || <b><i>Empty</i></b>
            }
        },
        {
            key: "10",
            title: 'Description',
            dataIndex: "description",
            render: ( description ) => {
                return description.en || <b><i>Empty</i></b>
            }
        },
    ]
    const studentsColumns = [
        {
            key: "1",
            title: 'Student',
            render: ( record ) => {
                return record?.name || <b><i>Empty</i></b>
            }
        },
        {
            key: "2",
            title: 'Last name',
            render: ( record ) => {
                return record?.lastName || <b><i>Empty</i></b>
            }
        },
        {
            key: "3",
            title: 'Email',
            render: ( record ) => {
                return record?.email || <b><i>Empty</i></b>
            }
        },
    ]


    const expandableColumns = [
        {
            key: "6",
            title: 'Week Days',
            render: ( packageData ) => {
                return packageData?.TimeLine?.weekDays || <b><i>Empty</i></b>
            }
        },
        {
            key: "7",
            title: 'Duration',
            render: ( packageData ) => {
                return packageData?.TimeLine?.duration || <b><i>Empty</i></b>
            }
        },
        {
            key: "8",
            title: 'Start & End',
            render: ( packageData ) => {
                return `${ packageData?.TimeLine?.dayStartTime || "empty" } && ${ packageData?.TimeLine?.dayFinishTime || "empty" }` ||
                    <b><i>Empty</i></b>
            }
        },
        {
            key: "9",
            title: 'Teacher',
            render: ( packageData ) => {
                return `${ packageData?.teacher?.name || "empty" } ${ packageData?.teacher?.lastName || "empty" }` ||
                    <b><i>Empty</i></b>
            }
        },
    ];

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Name',
            dataIndex: "name"
        },
        {
            key: "3",
            title: 'Package name',
            render: ( record ) => {
                return record?.package?.name || <b><i>Empty</i></b>
            }
        },
        {
            key: "4",
            title: 'Classroom',
            render: ( record ) => {
                return record?.package?.TimeLine?.classroom || <b><i>Empty</i></b>
            }
        },
        {
            key: "5",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editGroup",
                                transfer_id: e.id,
                                process_type: "group",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "group",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];

    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addGroup" ) )
                } }
        >Add Group <ProfileOutlined/> </Button>
        <SortableContext
            items={ groups.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( groupData ) => {
                        return <>
                            <h3 style={ {
                                userSelect: "none",
                                color: "#4DB671",
                                margin: "10px 0",
                                textAlign: "center"
                            } }>
                                Info
                                <sub style={ { fontSize: "8px" } }> { groupData?.name }</sub>
                            </h3>
                            <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ [ groupData?.package ] }
                                pagination={ false }
                            />
                            <h3 style={ {
                                userSelect: "none",
                                color: "#4DB671",
                                margin: "10px 0",
                                textAlign: "center"
                            } }>
                                Lessons
                                <sub style={ { fontSize: "8px" } }> { groupData?.name }</sub>
                            </h3>
                            <Table
                                rowKey="id"
                                columns={ lessonsColumns }
                                dataSource={ groupData?.package?.lessons || [] }
                                expandable={ {
                                    expandedRowRender: lesson => {
                                        return <Table
                                            rowKey="id"
                                            columns={ expandableSubLessonsColumns }
                                            dataSource={ lesson?.sub_lessons || [] }
                                            pagination={ false }
                                        />
                                    }
                                } }
                                pagination={ false }
                            />
                            <h3
                                style={ {
                                    userSelect: "none",
                                    color: "#4DB671",
                                    margin: "10px 0",
                                    textAlign: "center"
                                } }
                            >
                                Students
                                <sub style={ { fontSize: "8px" } }>{ groupData?.name }</sub>
                            </h3>
                            <Table
                                rowKey="id"
                                columns={ studentsColumns }
                                dataSource={ groupData?.students || [] }
                                pagination={ false }
                            />
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ groups }
            />
        </SortableContext>
    </div>
};
export default Groups;