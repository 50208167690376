import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Upload } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import {
    addMoreItemContent,
} from "../../../Redux/Slices/Content/asyncThunk";
import { globalBeforeUpload, GlobalFormItem, globalGetBase64, GlobalUploadButton } from "../../Common/globalFormItems";
import { dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const AddContentMoreItemData = () => {
    const dispatch = useDispatch()
    const page_id = useSelector( state => state.modal.transfer_id )
    const selected = useSelector( state => state.languages.selected )

    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ loading, setLoading ] = useState( false );


    const onFinish = ( value ) => {
        const data = {}
        for ( let prop in value ) {
            if ( value[ prop ] ) {
                if ( prop === "photo" ) {
                    data[ prop ] = value[ prop ].file.originFileObj
                } else {
                    data[ prop ] = value[ prop ]
                }
            }
        }
        if ( Object.keys( data ).length !== 0 && page_id ) {
            data.can_show = false
            dispatch( addMoreItemContent( { data, page_id, language: selected } ) )
            dispatch( setModalType( null ) )
        }
    };


    const handleChange = ( info ) => {
        if ( info.file ) {
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New content more item: ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/>
                        : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>
            <GlobalFormItem name="title" label="Title">
                <TextArea rows={ 3 } placeholder="Title"/>
            </GlobalFormItem>
            <GlobalFormItem name="text" label="Text">
                <TextArea rows={ 6 } placeholder="Description"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
