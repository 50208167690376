import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Checkbox, Select, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getGroups } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { editStudents } from "../../../Redux/Slices/Education/Students/asyncThunks";
import { GlobalFormItem, globalInputNumberProps } from "../../Common/globalFormItems";
import { compareObjects, handlerMutableObject } from "../../TimeLine/components/utils";


export const EditStudentData = () => {
    const dispatch = useDispatch()
    const students = useSelector( state => state.students.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_student = students.filter( student => student.id === transfer_id )[ 0 ]
    const [ isVerified, setVerificationState ] = useState( false )
    const [ physicalCert, setPhysicalCert ] = useState( false )
    const [ stopped, setStopped ] = useState( selected_student?.password?.permission?.stopped || false )
    const groups = useSelector( state => state.groups.all )
    const groupsOptions = groups.map( ( elem ) => {
        return {
            key: elem.id + elem.name,
            label: elem.name,
            value: `${ elem.id }`
        }
    } )

    const [ changedGroup, setGroup ] = useState()

    useEffect( () => {
        if ( !groups.length ) {
            dispatch( getGroups() )
        }
    }, [ dispatch, groups.length ] )


    const handleGroupChange = ( data ) => {
        const group = groups.filter( group => group.id === parseInt( data?.value ) )[ 0 ]
        setGroup( group )
    }

    const onFinish = ( value ) => {
        let data = handlerMutableObject( value, [ "group" ] )
        if ( value?.group ) {
            data.groupId = +value?.group?.value
        }
        if ( physicalCert ) {
            data.physicalCert = physicalCert
        }

        if ( Object.keys( data ).length ) {
            data.verified = isVerified
            data = compareObjects( data, selected_student )
            //TODO check
            data.stopped = stopped
            data.id = transfer_id
            dispatch( editStudents( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit Student ID: ${ transfer_id }` }
                 extra={
                     <Checkbox
                         disabled={ selected_student?.password?.permission?.verified }
                         defaultChecked={ selected_student?.password?.permission?.verified }
                         onChange={ ( e ) => setVerificationState( e.target.checked ) }
                     >
                         { selected_student?.password?.permission?.verified ? "Verified" : "Verify ?" }
                     </Checkbox>
                 }
                 style={ { width: 570 } }>
        <Form
            initialValues={ {
                "name": selected_student.name,
                "lastName": selected_student.lastName,
                "email": selected_student.email,
                "phone": parseInt( selected_student.phone ),
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }
        >
            <Checkbox
                disabled={ selected_student?.password?.permission?.physicalCert }
                defaultChecked={ selected_student?.password?.permission?.physicalCert }
                style={ { marginBottom: "20px" } }
                onChange={ ( e ) => setPhysicalCert( e.target.checked ) }
            >
                { selected_student?.physicalCert ? "Physical certified" : "Physical certify ?" }
            </Checkbox>
            <Checkbox
                // disabled={ selected_student?.password?.permission?.stopped }
                defaultChecked={ selected_student?.password?.permission?.stopped }
                style={ { margin: "0 0 20px 20px" } }
                onChange={ ( e ) => setStopped( e.target.checked ) }
            >
                { selected_student?.physicalCert ? "Stopped" : "Is stop ?" }
            </Checkbox>
            <GlobalFormItem isRequired={ false } name="name" label="First Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="lastName" label="Last Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="email" label="Email">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="phone" label="Phone">
                <InputNumber { ...globalInputNumberProps } />
            </GlobalFormItem>

            <GlobalFormItem name="group" label="Group">
                <Select
                    labelInValue
                    allowClear
                    style={ {
                        position: "relative",
                        margin: "15px 0",
                        width: '100%',
                    } }
                    placeholder={ groups?.filter( group => group.id === selected_student?.groupId )[ 0 ]?.name || "Group" }
                    onChange={ handleGroupChange }
                    options={ groupsOptions }
                />
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } label="Package" name="package">
                <Input disabled placeholder={
                    changedGroup?.package?.name || groups?.filter( group => group.id === selected_student?.groupId )[ 0 ]?.package?.name
                }/>
            </GlobalFormItem>


            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
