import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getAllEmailTypes } from "../../Redux/Slices/Education/Emails/asyncThunksCategory";
import { setModalType } from "../../Redux/Slices/modal";
import { Button, Card, Space } from "antd";
import LanguagesHeader from "../Common/languages";
import { v4 as uuidV4 } from "uuid";
import { chooseEmailType } from "../../Redux/Slices/Education/Emails/email";


const SendTypeEmail = () => {
    const dispatch = useDispatch()
    const allEmailTypes = useSelector( state => state.emailTypes.all )

    useEffect( () => {
        if ( !allEmailTypes.length ) {
            dispatch( getAllEmailTypes() )
        }
    }, [ dispatch, allEmailTypes.length ] )


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <LanguagesHeader/>
        <Space direction="vertical" size={ 16 } style={ {
            marginTop: "25px",
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        } }>
            <Card title="Emails" style={ { width: "100%", textAlign: "center" } }>
                <div style={ {
                    width: "100%",
                    maxWidth: "565px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "25px"
                } }>
                    {
                        allEmailTypes.map( ( email, index ) => {
                            return <Button
                                onClick={ () => {
                                    dispatch( setModalType( "sendTypeEmail" ) )
                                    dispatch( chooseEmailType( email ) )
                                } }
                                key={ uuidV4() }
                                type="primary"
                                size="large"
                            >
                                { email.label }
                            </Button>
                        } )
                    }
                </div>
            </Card>
        </Space>
    </div>
};
export default SendTypeEmail;