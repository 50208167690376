import { createSlice } from '@reduxjs/toolkit'
import { getTimeLines } from "./asyncThunks";


export const timeLines = createSlice({
    name: 'timeLines',
    initialState: {
        all: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTimeLines.fulfilled, (state, action) => {
            state.all = action.payload
        })
        builder.addCase(getTimeLines.rejected, (state, action) => {
            state.all = []
        })
    }
})

export const { } = timeLines.actions
export default timeLines.reducer