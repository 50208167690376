import { createSlice } from '@reduxjs/toolkit'
import { getPayments } from "./asyncThunks";

export const index = createSlice( {
    name: 'payments',
    initialState: {
        all: [],
    },
    reducers: {
        // setStudentsDataSource: ( state, action ) => {
        //     return state.all = action.payload
        // }
    },
    extraReducers: ( builder ) => {
        builder
            .addCase( getPayments.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

// export const { setStudentsDataSource } = index.actions
export default index.reducer