import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Upload, Image, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import Connections from "../../../Services/connections";
import { changeUserInfo } from "../../../Redux/Slices/Auth/asyncThunk";
import { useNavigate } from "react-router";
import {
    globalBeforeUpload,
    GlobalFormItem,
    globalGetBase64,
    globalInputNumberProps,
    GlobalUploadButton
} from "../../Common/globalFormItems";
import { compareObjects, dummyRequest, handlerMutableObject } from "../../TimeLine/components/utils";


export const EditUser = () => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const user = useSelector( state => state.authentication.user )
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ loading, setLoading ] = useState( false );

    const handleChange = ( info ) => {
        if ( info.file ) {
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };


    const onFinish = ( value ) => {
        let data = handlerMutableObject( value, [ "image" ] )
        data = compareObjects( data, user )
        const image = value?.image?.file?.originFileObj
        if ( Object.keys( data ).length || image ) {
            dispatch( changeUserInfo( {
                data,
                image,
                navigation
            } ) )
        }
        dispatch( setModalType( null ) )
    };
//TODO check input phone validation
    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ "Edit User" }
    >
        <Form
            initialValues={ {
                "name": user?.name,
                "lastName": user?.lastName,
                "phone": parseInt(user?.phone),
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="name" label="Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="lastName" label="Last name">
                <Input/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="phone" label="Phone">
                <InputNumber { ...globalInputNumberProps }/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ user?.photo } name="image" label="Image">
                <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url || Connections.GetImage( user?.photo ) ?
                        <Image preview={ false } src={ uploadedImage.url || Connections.GetImage( user?.photo ) }
                               alt="avatar"
                               style={ { width: '100%' } }/>
                        : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>
            <div style={ { marginTop: "50px", width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="link"
                    onClick={ () => dispatch( setModalType( "resetPassword" ) ) }
                    style={ { marginRight: "30px" } }
                >Reset password</Button>
            </div>
            <div style={ { marginTop: "50px", width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Change
                </Button>
            </div>
        </Form>
    </Card>
}
