import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Checkbox } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editCategory } from "../../../Redux/Slices/Education/Lessons/asyncThunksCategory";
import { GlobalFormItem } from "../../Common/globalFormItems";


export const EditCategory = () => {
    const dispatch = useDispatch()

    const selected = useSelector( state => state.languages.selected )
    // const allLanguages = useSelector( state => state.languages.data )
    const categories = useSelector( state => state.lessons.categories )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_category = categories?.filter( category => category.id === transfer_id )[ 0 ]
    const [ inLive, setLiveState ] = useState( selected_category?.can_show || null )


    const onFinish = ( value ) => {
        let data = {
            title: value.title,
            can_show: selected_category?.can_show
        }
        if ( inLive !== null && inLive !== selected_category.can_show ) {
            data.can_show = inLive
        }
        if ( selected_category.title[selected] !== data.title || selected_category.can_show !== data.can_show ) {
            dispatch( editCategory( { ...data, language: selected, id: transfer_id } ) )
        }
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Content ID: ${ transfer_id }` }
        extra={ <Checkbox
            defaultChecked={ selected_category?.can_show }
            onChange={ ( e ) => setLiveState( e.target.checked ) }
        >
            In Live
        </Checkbox> }
    >
        <Form
            initialValues={ {
                [ "title" ]: selected_category?.title && selected_category.title[ selected ],
            } }

            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="title" label="Title">
                <Input required/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Confirm
                </Button>
            </div>
        </Form>
    </Card>
}
