import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select, Upload, InputNumber, Switch } from 'antd';
import { setModalTransferData, setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { getLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { DeleteOutlined } from "@ant-design/icons";
import { editPackageExemplar } from "../../../Redux/Slices/ContentPackages/asyncThunk";
import Connection from "../../../Services/connections";
import { globalBeforeUpload, GlobalFormItem, globalGetBase64, GlobalUploadButton } from "../../Common/globalFormItems";
import { dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input


export const EditContentPackage = () => {
    const dispatch = useDispatch()
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const lessons = useSelector( state => state.lessons.all )
    const contentPackages = useSelector( state => state.contentPackages.all )
    const selectedPackage = contentPackages.filter( item => item.id === transfer_id )[ 0 ]
    const [ inLive, setInLiveState ] = useState( selectedPackage.in_live )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const lessonsOptions = lessons?.map( lesson => {
        return {
            key: uuidV4(),
            label: lesson.name,
            value: lesson.id,
        }
    } )

    useEffect( () => {
        dispatch( getLessons() )
    }, [ dispatch ] )

    const onFinish = ( value ) => {
        let data = {
            id: transfer_id
        }


        for ( let prop in value ) {
            if ( prop === "lessons" && typeof value.lessons[ 0 ] === "number" ) {
                data[ prop ] = value[ prop ]
            }
            if (
                ( prop === "duration" || prop === "price" )
                && `${ value[ prop ] }` !== selectedPackage[ prop ]
            ) {
                data[ prop ] = value[ prop ]
            }

            if ( prop === "photo" && value[ prop ].file ) {
                data[ prop ] = value[ prop ].file.originFileObj
            }

            if (
                prop !== "duration" && prop !== "price" && prop !== "photo" && prop !== "lessons" &&
                value[ prop ] && value[ prop ] !== selectedPackage[ prop ]
            ) {
                data[ prop ] = value[ prop ]
            }

        }

        if ( selectedPackage.in_live !== inLive ) {
            data.in_live = inLive
        }
        dispatch( editPackageExemplar( data ) )
        dispatch( setModalType( null ) )
    };


    const handleChange = ( info ) => {
        if ( info.file ) {
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const onChange = ( checked ) => {
        setInLiveState( checked )
    };

    return <Card
        style={ { position: "relative", width: 500, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Content Package: ${ transfer_id }` }
        extra={ <><b>In live</b> <Switch defaultChecked={ selectedPackage.in_live } onChange={ onChange }/></> }
    >
        <DeleteOutlined
            onClick={ () => {
                dispatch( setModalTransferData( {
                    modalType: "delete",
                    transfer_id: transfer_id,
                    process_type: "packagesExemplar",
                } ) )
            } }
            style={ {
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                left: "15px",
                fontSize: "22px",
                color: "red",
            } }
        />
        <Form
            name="form_item_path"
            layout="vertical"
            onFinish={ onFinish }
            initialValues={ {
                "name": selectedPackage.name,
                "description": selectedPackage.description,
                "photo": selectedPackage.photo,
                "price": parseInt( selectedPackage.price ),
                "pricePrefix": selectedPackage.pricePrefix,
                "duration": parseInt( selectedPackage.duration ),
                "durationPrefix": selectedPackage.durationPrefix,
                "lessons": selectedPackage?.lessons.map( lesson => {
                    return {
                        key: uuidV4(),
                        label: lesson.name,
                        value: lesson.id
                    }
                } )
                // ["position"]: selected_teacher.position,
            } }
        >

            <GlobalFormItem isRequired={ false } name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url || selectedPackage.photo ?
                        <img src={ uploadedImage.url || Connection.GetImage( selectedPackage.photo ) }
                             alt="avatar" style={ { width: '100%' } }/> : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="name" label="Name">
                <Input placeholder="Name"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <GlobalFormItem isRequired={ false } name="price" label="Price">
                    <InputNumber style={ { width: 200 } } max={ 300000 } maxLength={ 6 } placeholder="Price"/>
                </GlobalFormItem>
                <GlobalFormItem isRequired={ false } name="pricePrefix" label="Prefix">
                    <Select
                        // defaultValue="lucy"
                        style={ { width: 200 } }
                        placeholder="AMD"
                        onChange={ handleChange }
                        options={ [
                            { value: 'AMD', label: 'AMD' },
                            { value: 'USD', label: 'USD' },
                            { value: 'RUB', label: 'RUB', disabled: true }
                        ] }
                    />
                </GlobalFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <GlobalFormItem isRequired={ false } name="duration" label="Duration">
                    <InputNumber style={ { width: 200 } } max={ 29 } maxLength={ 2 } placeholder="Duration"/>
                </GlobalFormItem>
                <GlobalFormItem isRequired={ false } name="durationPrefix" label="Prefix">
                    <Select
                        style={ { width: 200 } }
                        placeholder="DAY"
                        onChange={ handleChange }
                        options={ [
                            { value: 'DAY', label: 'DAY' },
                            { value: 'Month', label: 'Month' },
                            { value: 'Year', label: 'Year', disabled: true }
                        ] }
                    />
                </GlobalFormItem>
            </div>

            <GlobalFormItem isRequired={ false } name="description" label="Description">
                <TextArea placeholder="Text about of package" rows={ 5 }/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="lessons" label="Lessons">
                <Select
                    mode="tags"
                    style={ {
                        position: "relative",
                        width: '100%',
                        margin: "10px 0 20px 0"
                    } }
                    placeholder="Lessons"
                    options={ lessonsOptions }
                />
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}


export default EditContentPackage