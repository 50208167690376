import React, { useEffect, useState } from "react"
import styles from "../styles/auth.module.scss"
import { fetchingDataWithAxiosMiddleware } from "../../../Redux/Slices/fetch";
import Connection from "../../../Services/connections";
import { Button, Card, Form, Input, notification } from "antd";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../Redux/Slices/loading";
import { minEightCharacters } from "../../Common/paterns";
import { useTranslation } from "react-i18next";


const resetTeacherPassword = async ( data, navigate, translation ) => {
    try {
        const formData = new FormData()
        formData.append( "token", data.token )
        formData.append( "password", data.password )
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            Connection.ResetPassword(),
            formData
        )
        return response.data
    } catch ( e ) {
        const status = parseInt( e.request.status )
        Connection.connectionIssue( status, navigate )
        notification.error( {
            placement: 'topRight',
            message: translation( "WrongFormEntries" ),
        } )
    }
}

const checkResetToken = async ( token, navigate, translation ) => {
    //TODO ENABLE LOADING SYSTEM
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            Connection.CheckResetToken( token )
        )
        return response.data
    } catch ( e ) {
        // const status = parseInt( e.request.status )
        // Connection.connectionIssue( status, navigate )
        notification.error( {
            placement: 'topRight',
            message: translation( "InvalidToken" )
        } )
    }
}

const ResetTeacherPassword = () => {
    const { t: translation } = useTranslation( "common" )
    const { t: content_translation, i18n } = useTranslation( "translations" )
    const { t: notifications_translation } = useTranslation( "notifications" )
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [ process, setProcess ] = useState( "start" )
    const [ form ] = Form.useForm();

    useEffect( () => {
        form.validateFields( [ 'password', 'confirm' ] );
    }, [ i18n.language, form ] );

    useEffect( () => {
        checkResetToken( params.token, navigate, notifications_translation ).then( r => r )
    }, [ checkResetToken, params.token, navigate ] )


    useEffect( () => {
        if ( process === "finish" ) {
            setTimeout( () => {
                navigate( "/auth" )
            }, 3000 )
        }
    }, [ process ] )


    const onFinish = ( data ) => {
        dispatch( setLoadingState( false ) )
        resetTeacherPassword( { password: data.password, token: params.token }, navigate, notifications_translation ).then( ( resp ) => {
            if ( resp?.message === "success" ) {
                setProcess( "finish" )
            }
        } ).catch( () => {
            dispatch( setLoadingState( false ) )
        } )
    }

    return <div className={ styles.main }>

        <Card title={ process === "start" ? content_translation( "ResetYourPassword" ) : translation( "successfully" ) }
              style={ {
                  padding: "20px 0",
                  width: "370px",
                  textAlign: "",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
              } }>

            {
                process === "start" ? < Form
                    form={ form }
                    style={ {
                        width: "300px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    } }
                    initialValues={ {
                        remember: true,
                    } }
                    onFinish={ onFinish }
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="password"
                        style={ { width: "100%" } }
                        rules={ [
                            {
                                required: true,
                                pattern: minEightCharacters,
                                message: content_translation( "MinimumPasswordConfig" ),
                            },
                        ] }
                        hasFeedback
                    >
                        <Input.Password placeholder={ content_translation( "NewPassword" ) }/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        style={ { width: "100%", marginTop: "10px" } }
                        dependencies={ [ 'password' ] }
                        hasFeedback
                        rules={ [
                            {
                                required: true,
                                message: content_translation( "PleaseConfirmPassword" ),
                            },
                            ( { getFieldValue } ) => ( {
                                validator( _, value ) {
                                    if ( !value || getFieldValue( 'password' ) === value ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject( new Error( content_translation( "PasswordsNotMatch" ) ) );
                                },
                            } ),
                        ] }
                    >
                        <Input.Password placeholder={ content_translation( "ConfirmPassword" ) }/>
                    </Form.Item>

                    <Form.Item>
                        <Button style={ { marginTop: "10px" } } type="primary" htmlType="submit">
                            { translation( "confirm" ) }
                        </Button>
                    </Form.Item>
                </Form> : <div>
                    { content_translation( "SuccessfullyResetPassword" ) }
                </div>
            }

        </Card>

    </div>
}

export default ResetTeacherPassword