import { createSlice } from '@reduxjs/toolkit'
import { getLessons } from "./asyncThunks";
import { getLessonsCategories } from "./asyncThunksCategory";


export const lessons = createSlice( {
    name: 'lessons',
    initialState: {
        all: [],
        categories: []
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getLessons.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
        builder
            .addCase( getLessonsCategories.fulfilled, ( state, action ) => {
                state.categories = action.payload;
            } );
    }
} )

export const {} = lessons.actions
export default lessons.reducer