

class Service {

    constructor() {
        this.proces_mode = process.env.REACT_APP_MODE;
        this.server_url = this.proces_mode === "DEV" ? process.env.REACT_APP_LOCAL_SERVER_URL : process.env.REACT_APP_SERVER_URL;
    }

    // LOGIN
    SignUp() {
        return `${ this.server_url }/admin/login`
    }

    UserData() {
        return `${ this.server_url }/admin/info`
    }

    CheckAccount() {
        return `${ this.server_url }/admin/verify`
    }

    resetUserPassword() {
        return `${ this.server_url }/admin/reset`
    }

    GetImage( image ) {
        return `${ this.server_url }/${ image }`
    }

    // CATEGORIES CURSES
    LessonsCategories( payload = "" ) {
        return `${ this.server_url }/education/categories/${ payload }`
    }

    // EMAILS TYPE
    EmailTypes() {
        return `${ this.server_url }/customer/email-messages`
    }

    // STUDENTS
    EducationStudents( id = "" ) {
        return `${ this.server_url }/education/students/${ id }`
    }

    HomeworksStudents( id = "" ) {
        return `${ this.server_url }/education/homeworks`
    }

    ExamResults( id = "" ) {
        return `${ this.server_url }/education/exam-results`
    }

    PaymentsStudents() {
        return `${ this.server_url }/education/payments`
    }

    // CUSTOMERS
    Customers( id = "" ) {
        return `${ this.server_url }/customer/${ id } `
    }

    // PERMISSIONS
    Permissions() {
        return `${ this.server_url }/admin/permissions `
    }

    //LANGUAGES
    GetLanguages() {
        return `${ this.server_url }/languages`
    }

    LanguageIcon( name ) {
        return `${ this.server_url }/languages/${ name }`
    }

    //QRS
    QrsAll() {
        return `${ this.server_url }/qrs`
    }

    //ADMINS
    Admins() {
        return `${ this.server_url }/admin/all`
    }

    AddAdmin() {
        return `${ this.server_url }/admin/add`
    }

    EditAdmin( id = "", type = "" ) {
        return `${ this.server_url }/admin/edit/${ type }/${ id }`
    }

    GenerateQR() {
        return `${ this.server_url }/qrs/generate`
    }

    PrintQR( id ) {
        return `${ this.server_url }/qrs/print/${ id }`
    }

    DeleteQR( id ) {
        return `${ this.server_url }/qrs/${ id }`
    }

    //CONTENT
    GetContentAll() {
        return `${ this.server_url }/content/all`
    }

    PutEditPageContent( id = "", language = "" ) {
        return `${ this.server_url }/content/page/${ id }/${ language }`
    }

    //Description start

    PostAddDescriptionContent( page_id, language ) {
        return `${ this.server_url }/content/description/${ page_id }/${ language }`
    }

    PutEditDescriptionContent( id = "", page_id, language = "" ) {
        return `${ this.server_url }/content/description/${ page_id }/${ id }/${ language }`
    }

    DeleteDescriptionContent( page_id, description_id ) {
        return `${ this.server_url }/content/description/${ page_id }/${ description_id }`
    }

    //Description end


    //MORE ITEM start

    PostAddMoreItemContent( page_id, language ) {
        return `${ this.server_url }/content/more/${ page_id }/${ language }`
    }

    PutEditMoreItemContent( more_item_id = "", page_id, language = "" ) {
        return `${ this.server_url }/content/more/${ page_id }/${ more_item_id }/${ language }`
    }

    DeleteMoreItemContent( page_id, more_info_id ) {
        return `${ this.server_url }/content/more/${ page_id }/${ more_info_id }`
    }

    // MORE ITEM end
    DeleteContent( id ) {
        return `${ this.server_url }/content/${ id }`
    }

    // TEACHERS

    EducationTeachers( id = "" ) {
        return `${ this.server_url }/education/teachers/${ id }`
    }

    // EXERCISES
    EducationExercises( language = "" ) {
        return `${ this.server_url }/education/exercises/${ language }`
    }

    // EXAMS
    GetAllExams() {
        return `${ this.server_url }/exams`
    }

    PostAddExam() {
        return `${ this.server_url }/exams`
    }

    ChangeInfo() {
        return `${ this.server_url }/admin/change-info/`
    }

    PostAddQuestion() {
        return `${ this.server_url }/exams/question`
    }

    PutEditExam() {
        return `${ this.server_url }/exams`
    }

    DeleteExam( id ) {
        return `${ this.server_url }/exams/${ id }`
    }

    DeleteQuestion( id ) {
        return `${ this.server_url }/exams/question/${ id }`
    }

    //GROUPS
    EducationGroups( id = "" ) {
        return `${ this.server_url }/education/groups/${ id }`
    }

    //PACKAGES
    EducationPackages( id = "" ) {
        return `${ this.server_url }/education/packages/${ id }`
    }

    EducationPackageExemplars( id = "" ) {
        return `${ this.server_url }/education/packages/exemplar/${ id }`
    }

    //LESSONS
    EducationLessons( info = "" ) {
        return `${ this.server_url }/education/lessons/${ info }`
    }

    EducationSubLessons( info = "" ) {
        return `${ this.server_url }/education/sub-lessons/${ info }`
    }

    //TIME LINES

    EducationTimeLines( id = "" ) {
        return `${ this.server_url }/education/time-lines/${ id }`
    }


    //Verification Line
    SendVerificationLink() {
        return `${ this.server_url }/education/students/verify`
    }

    SendLogicTestLink() {
        return `${ this.server_url }/education/students/logic`
    }

    StopResettingPassword( token ) {
        return `${ this.server_url }/admin/stop/reset/${ token }`
    }

    CheckResetToken( token ) {
        return `${ this.server_url }/admin/check/reset/${ token }`
    }

    ResetPassword() {
        return `${ this.server_url }/admin/reset/pass`
    }

}


export default new Service();