import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { getTeachers } from "../Teachers/asyncThunks";
import { setLoadingState } from "../../loading";
import { notification } from "antd";


export const getLessons = createAsyncThunk(
    'lessons/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.EducationLessons()
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.lessons;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);


export const addLesson = createAsyncThunk(
    'lessons/add',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( prop !== "language" ) {
                    formData.append( prop, data[ prop ] )
                }
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EducationLessons( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully Added",
                } );
            }
            return actions.dispatch( getLessons() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessons() )
        }
    }
);

export const editLesson = createAsyncThunk(
    'lessons/edit',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            for ( let prop in data ) {
                if ( prop !== "language" ) {
                    if ( data[ prop ] ) {
                        formData.append( prop, data[ prop ] )
                    }
                    if ( data[ prop ] === "-" ) {
                        formData.append( prop, "" )
                    }
                }
            }

            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EducationLessons( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully edited",
                } );
            }
            return actions.dispatch( getLessons() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessons() )
        }
    }
);


export const deleteLessons = createAsyncThunk(
    'lessons/delete',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.EducationLessons( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully Deleted",
                } );
            }
            return actions.dispatch( getLessons() )
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessons() )
        }
    }
);
