import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../fetch";
import Connections from "../../../Services/connections";
import { setLoadingState } from "../loading";
import { notification } from "antd";
import { handlerFormData } from "../../../Components/TimeLine/components/utils";


export const getContent = createAsyncThunk(
    "content/getAll",
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connections.GetContentAll()
            )
            actions.dispatch( setLoadingState( false ) )
            return response.data.content
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return [];
        }
    }
)

//---------------DESCRIPTION--------------------

export const addDescriptionContent = createAsyncThunk(
    "content/addDescription",
    async ( { data, language, page_id }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [], false );

            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.PostAddDescriptionContent( page_id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }

            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)


export const editDescriptionContent = createAsyncThunk(
    "content/editDescription",
    async ( { data, language, page_id, description_id }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [], false );
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.PutEditDescriptionContent( description_id, page_id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }
            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

export const deleteDescriptionContent = createAsyncThunk(
    "content/deleteDescription",
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connections.DeleteDescriptionContent( data.page_id, data.description_id )
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
            }
            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

//---------------MORE ITEM--------------------


export const addMoreItemContent = createAsyncThunk(
    "content/addMoreItem",
    async ( { data, language, page_id }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [ "photo" ], false );
            formData.append( "file", data.photo )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.PostAddMoreItemContent( page_id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }
            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)


export const editMoreItemContent = createAsyncThunk(
    "content/editMoreItem",
    async ( { data, language, page_id, more_item_id }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [ "photo" ], false );
            formData.append( "file", data.photo )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.PutEditMoreItemContent( more_item_id, page_id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }
            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

export const deleteMoreItemContent = createAsyncThunk(
    "content/deleteMoreItem",
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connections.DeleteMoreItemContent( data.page_id, data.more_item_id )
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
            }

            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

//-------------------------------------------

export const addContent = createAsyncThunk(
    "content/add",
    async ( { data, language, page_id }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data );

            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.PostAddDescriptionContent( page_id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }

            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

export const editContent = createAsyncThunk(
    "content/edit",
    async ( { data, language, id }, actions ) => {
        try {
            const formData = new FormData();
            for ( let prop in data ) {
                if ( data[ prop ] !== null && data[ prop ] !== undefined ) {
                    formData.append( `${ prop }`, data[ prop ] )
                }
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connections.PutEditPageContent( id, language ),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }

            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)


export const deleteContent = createAsyncThunk(
    "content/delete",
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connections.DeleteContent( data.id )
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }

            return actions.dispatch( getContent() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getContent() )
        }
    }
)

