import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Checkbox, Upload } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editMoreItemContent } from "../../../Redux/Slices/Content/asyncThunk";
import Connection from "../../../Services/connections";
import { globalBeforeUpload, GlobalFormItem, globalGetBase64, GlobalUploadButton } from "../../Common/globalFormItems";
import { dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const EditContentMoreItemData = () => {
    const dispatch = useDispatch()
    const more_item_id = useSelector( state => state.modal.transfer_id )
    const page_id = useSelector( state => state.modal.process_type )
    const allContent = useSelector( state => state.content.all )
    const selected_page = allContent.filter( content => content.id === page_id )[ 0 ]
    const selected_more_item = selected_page?.MoreItems?.filter( more_item => more_item.id === more_item_id )[ 0 ]
    const selected = useSelector( state => state.languages.selected )

    const [ inLive, setLiveState ] = useState( null )
    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const live_switcher = () => {
        let can_show = false
        for ( let prop in selected_more_item.title ) {
            if ( !selected_more_item.title[ prop ] && prop !== "ru" ) {
                can_show = true
            }
        }
        for ( let prop in selected_more_item.text ) {
            if ( !selected_more_item.text[ prop ] && prop !== "ru" ) {
                can_show = true
            }
        }
        return can_show
    }

    const onFinish = ( value ) => {
        const data = {}
        if ( inLive !== null ) {
            data.can_show = inLive
        }
        for ( let prop in value ) {
            if ( prop === "photo" && value[ prop ].file ) {
                data[ prop ] = value[ prop ].file.originFileObj
            }
            if ( value[ prop ] && value[ prop ] !== selected_more_item[ prop ] && prop !== "photo" ) {
                data[ prop ] = value[ prop ]
            }
        }


        if ( Object.keys( data ).length !== 0 && more_item_id && page_id ) {
            dispatch( editMoreItemContent( { data, page_id, more_item_id, language: selected } ) )
            dispatch( setModalType( null ) )
        }
    };

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit more item: ${ selected.toUpperCase() }` }
        extra={ <Checkbox
            disabled={ live_switcher() }
            defaultChecked={ selected_more_item?.can_show || false }
            onChange={ ( e ) => setLiveState( e.target.checked ) }
        >
            In Live
        </Checkbox> }
    >
        <Form
            initialValues={ {
                "title": selected_more_item?.title[ selected ],
                "text": selected_more_item?.text[ selected ],
                "photo": selected_more_item.icon
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url || selected_more_item.icon ?
                        <img src={ uploadedImage.url || Connection.GetImage( selected_more_item.icon ) }
                             alt="Invalid" style={ { width: '100%' } }/> : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>

            <GlobalFormItem name="title" label="Title">
                <TextArea rows={ 3 } placeholder="Title" required/>
            </GlobalFormItem>
            <GlobalFormItem name="text" label="Text">
                <TextArea rows={ 6 } placeholder="Description" required/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
