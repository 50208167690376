import React from "react";
import { Form, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const GlobalFormItemContext = React.createContext( [] );

function toArr( str ) {
    return Array.isArray( str ) ? str : [ str ];
}

export const globalBeforeUpload = ( file ) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if ( !isJpgOrPng ) {
        notification.error( {
            placement: 'topRight',
            message: "You can only upload JPG/PNG file!",
        } );
    }
    const isLt1M = file.size / 1024 / 1024 < 1; // Check if file size is less than 1MB
    if ( !isLt1M ) {
        notification.error( {
            placement: 'topRight',
            message: 'Image size must be 1MB or less.',
        } );
    }
    return isJpgOrPng && isLt1M;
};
export const globalInputNumberProps = {
    minLength: 8,
    maxLength: 9,
    style: {
        width: "100%"
    }
}

export const globalGetBase64 = ( img, callback ) => {
    const reader = new FileReader();
    reader.addEventListener( 'load', () => callback( reader.result ) );
    reader.readAsDataURL( img );
};

export const globalGetBase64ForMore = ( file ) =>
    new Promise( ( resolve, reject ) => {
        const reader = new FileReader();
        reader.readAsDataURL( file );
        reader.onload = () => resolve( reader.result );
        reader.onerror = ( error ) => reject( error );
    } );

export const GlobalFormItemGroup = ( { prefix, children } ) => {
    const prefixPath = React.useContext( GlobalFormItemContext );
    const concatPath = React.useMemo( () => [ ...prefixPath, ...toArr( prefix ) ], [ prefixPath, prefix ] );
    return <GlobalFormItemContext.Provider value={ concatPath }>{ children }</GlobalFormItemContext.Provider>;
};

export const GlobalFormItem = ( { isRequired = true, name, ...props } ) => {
    const prefixPath = React.useContext( GlobalFormItemContext );
    const concatName = name !== undefined ? [ ...prefixPath, ...toArr( name ) ] : undefined;
    const getRules = ( name = "" ) => {
        if ( name === "email" ) {
            return [
                {
                    required: isRequired,
                    type: "email",
                    message: "The input is not valid E-mail!",
                },
            ]
        }
        if ( name === "phone" ) {
            return [
                {
                    required: isRequired,
                    type: "number",
                    message: "This field cannot be left blank",
                },
            ]
        }
        if ( name === "photo" ) {
            return [
                {
                    required: isRequired,
                    // type: "string",
                    message: "You must upload a photo to create a Package",
                },
            ]
        }
        if ( name === "duration" ) {
            return [
                {
                    required: isRequired,
                    type: "number",
                },
            ]
        }
        return [ { required: isRequired, message: `This field cannot be left blank` } ]
    }


    return <Form.Item rules={ getRules( concatName[ 0 ] ) } name={ concatName } { ...props } />;
};


export const GlobalUploadButton = ( { loading } ) => {
    return <button style={ { border: 0, background: 'none' } } type="button">
        { loading ? <LoadingOutlined/> : <PlusOutlined/> }
        <div style={ { marginTop: 8 } }>Upload</div>
    </button>
};
