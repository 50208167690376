import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editSubLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunksSubLessons";
import { GlobalFormItem } from "../../Common/globalFormItems";

const { TextArea } = Input

export const EditSubLesson = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const lesson_id = useSelector( state => state.modal.transfer_id )
    const sub_lesson_id = useSelector( state => state.modal.process_type )
    const lessons = useSelector( state => state.lessons.all )
    const selectedLesson = lessons?.filter( lesson => lesson?.id === lesson_id )[ 0 ],
        selectedSubLesson = selectedLesson?.sub_lessons.filter( sub => sub?.id === parseInt( sub_lesson_id ) )[ 0 ]

    const onFinish = ( value ) => {
        let finalObject = {
            lessonId: lesson_id,
            id: sub_lesson_id
        }
        for ( let prop in value ) {
            if ( value[ prop ] !== selectedSubLesson[ prop ] ) {
                finalObject[ prop ] = value[ prop ]
            }
        }
        finalObject.language = selected
        dispatch( editSubLesson( finalObject ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit Sub Lesson || ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }
            initialValues={ {
                [ "title" ]: selectedSubLesson.title[ selected ],
                [ "description" ]: selectedSubLesson.description[ selected ]
            } }
        >

            <GlobalFormItem isRequired={ !selectedSubLesson.title[ selected ] } name="title" label="Title">
                <Input placeholder="Title"/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ !selectedSubLesson.title[ selected ] } name="description" label="Description">
                <TextArea rows={ 2 } placeholder="Description"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}
