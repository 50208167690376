import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Card, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addCustomer } from "../../../Redux/Slices/Customer/asyncThunks";
import { GlobalFormItem } from "../../Common/globalFormItems";
import { handlerMutableObject } from "../../TimeLine/components/utils";

export const AddCustomerData = () => {
    const dispatch = useDispatch()

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )
        if ( Object.keys( data ).length ) {
            dispatch( addCustomer( data ) )
        }
        dispatch( setModalType( null ) )
    };

    return <Card title={ `Add Customer` }
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <Form name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="name" label="First Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="lastName" label="Last Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="email" label="Email">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="phone" label="Phone">
                <InputNumber
                    minLength={ 8 }
                    maxLength={ 9 }
                    style={ { width: "100%" } }
                />
            </GlobalFormItem>
            <GlobalFormItem name="contactType" label="Contact Type">
                <Input/>
            </GlobalFormItem>
            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
