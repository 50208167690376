import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Upload, Image, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import Connections from "../../../Services/connections";
import { useNavigate } from "react-router";
import { editAdmin } from "../../../Redux/Slices/Admins/asyncThunks";
import {
    globalBeforeUpload,
    globalGetBase64,
    GlobalFormItem,
    GlobalUploadButton,
    globalInputNumberProps
} from "../../Common/globalFormItems";
import { dummyRequest, handlerMutableObject } from "../../TimeLine/components/utils";


export const EditAdmin = () => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const admins = useSelector( state => state.admins.all )
    const user = admins.filter( admin => admin.id === transfer_id )[ 0 ]

    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ loading, setLoading ] = useState( false );


    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };


    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )
        if ( Object.keys( data ).length !== 0 ) {
            dispatch( editAdmin( {
                data: { ...value, id: transfer_id },
                image: value?.image?.file?.originFileObj,
                navigation
            } ) )
        }
        dispatch( setModalType( null ) )
    };

    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `EDIT User -> ${ user.id } ${ user.name } ${ user.lastName }` }
    >
        <Form name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem isRequired={ false } name="name" label="Name">
                <Input placeholder={ user?.name }/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="lastName" label="Last name">
                <Input placeholder={ user?.lastName }/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="phone" label="Phone">
                <InputNumber placeholder={ user?.phone } { ...globalInputNumberProps } />
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="image" label="Image">
                <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url || Connections.GetImage( user?.photo ) ?
                        <Image preview={ false } src={ uploadedImage.url || Connections.GetImage( user?.photo ) }
                               alt="avatar"
                               style={ { width: '100%' } }/>
                        : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>
            <div style={ { marginTop: "50px", width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Change
                </Button>
            </div>
        </Form>
    </Card>
}
