import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Upload, Image, Select, Checkbox } from 'antd';
import { setModalType } from "../../../../Redux/Slices/modal";
import Connections from "../../../../Services/connections";
import {
    GlobalFormItem,
    globalGetBase64ForMore,
    GlobalUploadButton
} from "../../../Common/globalFormItems";
import { dummyRequest, handlerMutableObject } from "../../../TimeLine/components/utils";
import { editExercise } from "../../../../Redux/Slices/Education/Exercises/asyncThunk";
import { getLessons } from "../../../../Redux/Slices/Education/Lessons/asyncThunks";
import { v4 as uuidV4 } from "uuid";


export const EditExercise = () => {
    const dispatch = useDispatch()
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const language = useSelector( state => state.languages.selected )
    const exercises = useSelector( state => state.exercises.all )
    const lessons = useSelector( state => state.lessons.all )
    const exercise = exercises.filter( item => item.id === transfer_id )[ 0 ]


    useEffect( () => {
        if ( !lessons.length ) {
            dispatch( getLessons() )
        }
    }, [ dispatch, lessons ] )


    const [ previewOpen, setPreviewOpen ] = useState( false );
    const [ previewImage, setPreviewImage ] = useState( '' );
    const [ fileList, setFileList ] = useState( [] );
    const [ mutatedImageData, changeLastExerciseImages ] = useState( exercise?.photo?.split( "," ) || [] );
    const [ can_show, ChangeLiveState ] = useState( exercise?.can_show || false );


    const lessonsOptions = []
    lessons?.forEach( lesson => {
        if ( Array.isArray( lesson.sub_lessons ) ) {
            lesson.sub_lessons.forEach( sub_lesson => {
                lessonsOptions.push( {
                    key: uuidV4(),
                    label: `${ lesson.name } || ${ sub_lesson.title[ language ] }`,
                    value: sub_lesson.id,
                } )
            } )
        }
    } )

    const defaultSubLesson = lessonsOptions.filter( item => item.value === exercise.sub_lesson.id )[ 0 ]

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value, [ "photos", "can_show" ] )

        if ( fileList.length ) {
            data.photos = fileList.map( file => {
                return file?.originFileObj
            } )
        }

        if ( Object.keys( data ).length ) {
            data.id = transfer_id
            data.can_show = can_show
            data.language = language
            data.mutatedImageData = mutatedImageData.toString()
            dispatch( editExercise( data ) )
        }
        dispatch( setModalType( null ) )
    };

    const handlePreview = async ( file ) => {
        if ( !file.url && !file.preview ) {
            file.preview = await globalGetBase64ForMore( file.originFileObj )
        }
        setPreviewImage( file.url || file.preview );
        setPreviewOpen( true );
    };


    const handleChangeImages = ( { fileList: newFileList } ) => setFileList( newFileList.map( item => {
        return { ...item, status: "success" }
    } ) );

    return <Card
        style={ {
            position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column"
        } }
        title={ `Edit exercise ID:${ exercise?.id }  language:${ language.toUpperCase() }` }
    >
        <Form
            initialValues={ {
                "title": exercise.title[ language ],
                "description": exercise.description[ language ]
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem isRequired={ false } name="can_show">
                <Checkbox defaultChecked={ can_show }
                          onChange={ () => ChangeLiveState( prev => !prev ) }>{ can_show ? "In Live" : "Waiting" }</Checkbox>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="subLessonId" label="Sub Lessons">
                <Select
                    // mode=""
                    style={ {
                        position: "relative",
                        width: '100%',
                        margin: "10px 0 20px 0"
                    } }
                    defaultValue={ defaultSubLesson }
                    placeholder="Lessons"
                    optionFilterProp="label"
                    showSearch={ true }
                    options={ lessonsOptions }
                />
            </GlobalFormItem>

            <GlobalFormItem name="title" label="Exercises title">
                <Input.TextArea rows={ 3 }/>
            </GlobalFormItem>

            <GlobalFormItem name="description" label="Description">
                <Input.TextArea rows={ 5 }/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ false } name="photos" label={ "Photos" }>
                <div style={ { margin: "25px 0", width: '100%', display: "flex", gap: "20px", flexWrap: "wrap" } }>
                    { mutatedImageData.length ? mutatedImageData.map( image => {
                        return <div key={ uuidV4() } style={ { gap: "5px" } }>
                            <Image
                                preview={ false }
                                onDoubleClick={ () => {
                                    changeLastExerciseImages( prev => {
                                        return prev.filter( text => text !== image )
                                    } )
                                } }
                                style={ {
                                    width: "100px",
                                    height: "100px"
                                } }
                                src={ Connections.GetImage( image ) }
                            />
                        </div>
                    } ) : null }
                </div>
                <Upload
                    action=""
                    listType="picture-card"
                    accept="image/*"
                    fileList={ fileList }
                    customRequest={ dummyRequest }
                    onPreview={ handlePreview }
                    onChange={ handleChangeImages }
                >
                    { fileList.length >= 8 ? null : <GlobalUploadButton/> }
                </Upload>
                { previewImage && (
                    <Image
                        wrapperStyle={ { display: 'none' } }
                        preview={ {
                            visible: previewOpen,
                            onVisibleChange: ( visible ) => setPreviewOpen( visible ),
                            afterOpenChange: ( visible ) => !visible && setPreviewImage( '' ),
                        } }
                        src={ previewImage }
                    />
                ) }
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}


export default EditExercise