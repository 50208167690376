import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addSubLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunksSubLessons";
import { GlobalFormItem } from "../../Common/globalFormItems";
import { handlerMutableObject } from "../../TimeLine/components/utils";

const { TextArea } = Input


export const AddSubLesson = () => {
    const dispatch = useDispatch()
    const lessonId = useSelector( state => state.modal.transfer_id )
    const selected = useSelector( state => state.languages.selected )

    const onFinish = ( value ) => {
        //TODO Check send fields
        let data = handlerMutableObject( value )
        if ( Object.keys(data).length ) {
            data.langId = 1
            data.lessonId = lessonId
            data.language = selected
            dispatch( addSubLesson( data ) )
        }
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Sub Lesson || ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="title" label="Title">
                <Input placeholder="Title"/>
            </GlobalFormItem>

            <GlobalFormItem name="description" label="Description">
                <TextArea rows={ 2 } placeholder="Description"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
