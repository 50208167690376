import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select, Upload, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { getLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { addPackageExemplar } from "../../../Redux/Slices/ContentPackages/asyncThunk";
import { globalBeforeUpload, GlobalFormItem, globalGetBase64, GlobalUploadButton } from "../../Common/globalFormItems";
import { dummyRequest, handlerMutableObject } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const AddContentPackage = () => {
    const dispatch = useDispatch()
    const lessons = useSelector( state => state.lessons.all )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const lessonsOptions = lessons?.map( lesson => {
        return {
            key: uuidV4(),
            label: lesson.name,
            value: `${ lesson.id }`,
        }
    } )

    useEffect( () => {
        dispatch( getLessons() )
    }, [ dispatch ] )

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value, [ "photo" ] )
        if ( value.photo ) {
            data.photo = value.photo.file?.originFileObj
        }
        if ( Object.keys( data ).length ) {
            data.is_exemplar = true
            data.in_live = false
            dispatch( addPackageExemplar( data ) )
        }
        dispatch( setModalType( null ) )
    };


    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    return <Card
        style={ { position: "relative", width: 500, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Content Package` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt={ uploadedImage?.file.name || "uploaded material" }
                             style={ { width: '100%' } }/> :
                        <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>

            <GlobalFormItem name="name" label="Name">
                <Input placeholder="Name"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <GlobalFormItem name="price" label="Price">
                    <InputNumber style={ { width: 200 } } max={ 300000 } maxLength={ 6 } placeholder="Price"/>
                </GlobalFormItem>
                <GlobalFormItem name="pricePrefix" label="Prefix">
                    <Select
                        // defaultValue="lucy"
                        style={ { width: 200 } }
                        placeholder="AMD"
                        onChange={ handleChange }
                        options={ [
                            { value: 'AMD', label: 'AMD' },
                            { value: 'USD', label: 'USD' },
                            { value: 'RUB', label: 'RUB', disabled: true }
                        ] }
                    />
                </GlobalFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "space-between" } }>
                <GlobalFormItem name="duration" label="Duration">
                    <InputNumber style={ { width: 200 } } max={ 29 } maxLength={ 2 } placeholder="Duration"/>
                </GlobalFormItem>
                <GlobalFormItem name="durationPrefix" label="Prefix">
                    <Select
                        style={ { width: 200 } }
                        placeholder="DAY"
                        onChange={ handleChange }
                        options={ [
                            { value: 'DAY', label: 'DAY' },
                            { value: 'Month', label: 'Month' },
                            { value: 'Year', label: 'Year', disabled: true }
                        ] }
                    />
                </GlobalFormItem>
            </div>

            <GlobalFormItem name="description" label="Description">
                <TextArea placeholder="Text about of package" rows={ 5 }/>
            </GlobalFormItem>

            <GlobalFormItem name="lessons" label="Lessons">
                <Select
                    mode="tags"
                    style={ {
                        position: "relative",
                        width: '100%',
                        margin: "10px 0 20px 0"
                    } }
                    placeholder="Lessons"
                    options={ lessonsOptions }
                />
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}


export default AddContentPackage