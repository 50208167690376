import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";
// import { handlerFormData } from "../../../../Components/TimeLine/components/utils";

export const getHomeworks = createAsyncThunk(
    'homeworks/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.HomeworksStudents(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.homeworks;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);
//
// export const addStudents = createAsyncThunk(
//     'students/add',
//     async ( data, actions ) => {
//         actions.dispatch( setLoadingState( true ) )
//         try {
//             const formData = handlerFormData( data )
//
//             const response = await fetchingDataWithAxiosMiddleware(
//                 "POST",
//                 Connection.EducationStudents(),
//                 formData
//             );
//
//             if ( response.data.message === "success" ) {
//                 notification.success( {
//                     placement: 'topRight',
//                     message: "Added successfully",
//                 } );
//                 return actions.dispatch( getStudents() )
//             }
//         } catch ( e ) {
//             const errorMessage = await e.response.data.message || "connection error";
//             notification.error( {
//                 placement: 'topLeft',
//                 message: errorMessage
//             } );
//             return actions.dispatch( getStudents() )
//         }
//     }
// );
//
// export const editStudents = createAsyncThunk(
//     'students/edit',
//     async ( data, actions ) => {
//         actions.dispatch( setLoadingState( true ) )
//         try {
//             const formData = handlerFormData( data, [], false )
//
//             const response = await fetchingDataWithAxiosMiddleware(
//                 "PUT",
//                 Connection.EducationStudents(),
//                 formData
//             );
//
//             if ( response.data.message === "success" ) {
//                 notification.success( {
//                     placement: 'topRight',
//                     message: "Edited successfully",
//                 } );
//                 return actions.dispatch( getStudents() )
//             }
//         } catch ( e ) {
//             const errorMessage = await e.response.data.message || "connection error";
//             notification.error( {
//                 placement: 'topLeft',
//                 message: errorMessage
//             } );
//             return actions.dispatch( getStudents() )
//         }
//     }
// );
//
// export const deleteStudents = createAsyncThunk(
//     'students/delete',
//     async ( data, actions ) => {
//         try {
//             actions.dispatch( setLoadingState( true ) )
//             const response = await fetchingDataWithAxiosMiddleware(
//                 "DELETE",
//                 Connection.EducationStudents( data.id ),
//             );
//
//             if ( response.data.message === "success" ) {
//                 notification.success( {
//                     placement: 'topRight',
//                     message: "Deleted successfully",
//                 } );
//                 return actions.dispatch( getStudents() )
//             }
//         } catch ( e ) {
//             const errorMessage = await e.response.data.message || "connection error";
//             notification.error( {
//                 placement: 'topLeft',
//                 message: errorMessage
//             } );
//             return actions.dispatch( getStudents() )
//         }
//     }
// );
