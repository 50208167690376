import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Radio } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addQuestion } from "../../../Redux/Slices/Education/Exams/asyncThunk";
import { GlobalFormItem } from "../../Common/globalFormItems";
import { handlerMutableObject } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const AddExamQuestionData = () => {
    const dispatch = useDispatch()
    const language = useSelector( state => state.languages.selected )
    const transfer_id = useSelector( state => state.modal.transfer_id )

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )
        dispatch( addQuestion( { ...data, language, examId: transfer_id } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Exam ID: ${ transfer_id } - ${ language.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="title" label="title">
                <Input placeholder="Ex. Question 1"/>
            </GlobalFormItem>
            <GlobalFormItem name="question" label="Question">
                <TextArea rows={ 3 } placeholder="Write your question here."/>
            </GlobalFormItem>

            <GlobalFormItem label="Correct Answer" name="correct_answer">
                <Radio.Group>
                    <Radio.Button value="answer_a">A</Radio.Button>
                    <Radio.Button value="answer_b">B</Radio.Button>
                    <Radio.Button value="answer_c">C</Radio.Button>
                    <Radio.Button value="answer_d">D</Radio.Button>
                </Radio.Group>
            </GlobalFormItem>
            <GlobalFormItem name="answer_a" label="Answer_a">
                <Input placeholder="A) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem name="answer_b" label="Answer_b">
                <Input placeholder="B) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="answer_c" label="Answer_c">
                <Input placeholder="C) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="answer_d" label="Answer_d">
                <Input placeholder="D) Your answer here"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
