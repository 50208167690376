import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { getPackages } from "../../../Redux/Slices/Education/Packages/asyncThunks";
import { v4 as uuidV4 } from "uuid"
import { editGroup } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { getTeachers } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { GlobalFormItem } from "../../Common/globalFormItems";
import { handlerMutableObject } from "../../TimeLine/components/utils";


export const EditGroup = () => {
    const dispatch = useDispatch()
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const groups = useSelector( state => state.groups.all )
    const packages = useSelector( state => state.packages.all )
    const teachers = useSelector( state => state.teachers.all )
    const selectedGroup = groups.filter( group => group.id === transfer_id )[ 0 ]

    const [ selectedPackage, selectPackage ] = useState( null )
    const packagesOption = packages.filter( elem => elem.groupId === null ).map( item => {
        return {
            key: uuidV4(),
            label: item.name,
            value: item.id
        }
    } )

    const handleChange = ( value ) => {
        selectPackage( packages.filter( item => item.id === value.value )[ 0 ] )
    };

    useEffect( () => {
        dispatch( getPackages() )
        dispatch( getTeachers() )
    }, [ dispatch ] )


    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )
        if ( Object.keys( data ).length ) {
            data.id = transfer_id
            data.packageId = selectedPackage?.id
            dispatch( editGroup( data ) )
        }
        dispatch( setModalType( null ) )
    };

    const payload = selectedPackage?.id ? selectedPackage : selectedGroup?.package
    return <Card
        style={ {
            position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column"
        } }
        title={ `Edit Group ${ payload?.id }` }
    >
        <Form
            initialValues={ {
                "name": selectedGroup?.name,
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="name" label="Group name">
                <Input placeholder="Group name"/>
            </GlobalFormItem>
            <Select
                labelInValue
                allowClear
                style={ {
                    position: "relative",
                    width: '100%',
                } }
                placeholder={ payload?.name }
                onChange={ handleChange }
                options={ packagesOption }
            />
            <GlobalFormItem isRequired={ false } name="teacher" label="Teacher">
                <Input
                    placeholder={ `${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.name || "Teacher"
                    } ${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.lastName || "name lastname"
                    } ${ teachers.filter( teacher => teacher.id === payload?.teacherId )[ 0 ]?.position || "position" }` }
                    disabled/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="weekDays" label="Week Days">
                <Input placeholder={ payload?.TimeLine?.weekDays || "week days" } disabled/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="duration" label="Duration">
                <Input placeholder={ payload?.TimeLine?.duration || "duration" } disabled/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="StartEnd" label="Start & End">
                <Input
                    placeholder={ `${ payload?.TimeLine?.dayStartTime || "Start" } && ${ payload?.TimeLine?.dayFinishTime || "End" }` }
                    disabled/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ false } name="classroom" label="Classroom">
                <Input placeholder={ payload?.TimeLine?.classroom || "Classroom" } disabled/>
            </GlobalFormItem>

            {
                payload?.lessons?.map( lesson => {
                    return <GlobalFormItem isRequired={ false } key={ uuidV4() } name="lesson" label="lesson">
                        <Input placeholder={ lesson?.name } disabled/>
                    </GlobalFormItem>
                } )
            }

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button disabled={ !selectedPackage?.id } type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}


export default EditGroup