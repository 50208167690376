import { createSlice } from '@reduxjs/toolkit'
import { getAdmins } from "./asyncThunks";

export const admins = createSlice( {
    name: 'admins',
    initialState: {
        all: [],
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getAdmins.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

// export const { } = index.actions
export default admins.reducer