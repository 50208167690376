import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addContent } from "../../../Redux/Slices/Content/asyncThunk";
import { GlobalFormItem } from "../../Common/globalFormItems";

const { TextArea } = Input



export const AddContentData = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )

    const onFinish = ( value ) => {
        dispatch( addContent( { ...value, langId: selected } ) )
        dispatch( setModalType( null ) )
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New content` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="name" label="Content name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="title" label="Title">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="previewDesc" label="Short text">
                <TextArea rows={ 5 }/>
            </GlobalFormItem>
            <GlobalFormItem name="description" label="Description">
                <TextArea rows={ 10 }/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
