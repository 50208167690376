import styles from "./styles/modal.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { setModalType } from "../../Redux/Slices/modal";
import { GenerateQRModal } from "../QR/components/generateQR";
import DeleteModal from "./components/deleteModal";
import { EditStudentData } from "../Students/components/editStudent";
import { LogoutConfirmModal } from "./components/logoutModal";
import { EditContentData } from "../Content/components/editContent";
import { EditTeacherData } from "../Teachers/components/editTeacher";
import { AddContentData } from "../Content/components/addContent";
import { AddStudentData } from "../Students/components/addStudent";
import { AddTeacherData } from "../Teachers/components/addTeacher";
import { AddExamData } from "../Exam/components/addExam";
import { EditExamData } from "../Exam/components/editExam";
import { AddExamQuestionData } from "../Exam/components/addQuestion";
import AddGroup from "../Groups/components/addGroup";
import { useEffect } from "react";
import EditGroup from "../Groups/components/editGroup";
import { AddTimeData } from "../TimeLine/components/addTime";
import { EditTimeData } from "../TimeLine/components/editTime";
import { AddLesson } from "../Lessons/components/addLesson";
import { EditLesson } from "../Lessons/components/editLesson";
import { AddSubLesson } from "../Lessons/components/addSubLesson";
import { EditSubLesson } from "../Lessons/components/editSubLesson";
import { AddPackage } from "../Packages/components/addPackage";
import { EditPackage } from "../Packages/components/editPackage";
import AddContentPackage from "../ContentPackages/components/addContentPackage";
import EditContentPackage from "../ContentPackages/components/editContentPackage";
import { AddContentDescriptionData } from "../Content/components/addContentDescription";
import { EditContentDescriptionData } from "../Content/components/editContentDescription";
import { AddContentMoreItemData } from "../Content/components/addContentMoreItem";
import { EditContentMoreItemData } from "../Content/components/editContentMoreItem";
import { AddCustomerData } from "../Customer/components/addCustomer";
import { EditCustomerData } from "../Customer/components/editCustomer";
import { AddCategory } from "../LessonsCategories/components/addCategory";
import { EditCategory } from "../LessonsCategories/components/editCategory";
import { SendTypeEmailData } from "../Emails/components/sendTypeEmail";
import { EditUser } from "../User/components/editUser";
import { AddAdmin } from "../Admins/components/addAdmin";
import { EditAdmin } from "../Admins/components/editAdmin";
import AddExercise from "../Education/Exercises/components/addExercise";
import EditExercise from "../Education/Exercises/components/editExercise";
import { ResetPasswordModal } from "../User/components/resetPassword";

const modalTypes = {
    addExam: <AddExamData/>,
    delete: <DeleteModal/>,
    generateQR: <GenerateQRModal/>,
    editStudent: <EditStudentData/>,
    addStudent: <AddStudentData/>,
    editContent: <EditContentData/>,
    addContent: <AddContentData/>,
    addContentDescription: <AddContentDescriptionData/>,
    editContentDescription: <EditContentDescriptionData/>,
    addContentMoreItem: <AddContentMoreItemData/>,
    editContentMoreItem: <EditContentMoreItemData/>,
    editTeacher: <EditTeacherData/>,
    addTeacher: <AddTeacherData/>,
    editExam: <EditExamData/>,
    addExamQuestion: <AddExamQuestionData/>,
    addGroup: <AddGroup/>,
    editGroup: <EditGroup/>,
    addCustomer: <AddCustomerData/>,
    editCustomer: <EditCustomerData/>,
    addTime: <AddTimeData/>,
    editTimeLine: <EditTimeData/>,
    addLesson: <AddLesson/>,
    editLesson: <EditLesson/>,
    addSubLesson: <AddSubLesson/>,
    editSubLesson: <EditSubLesson/>,
    addPackage: <AddPackage/>,
    editPackage: <EditPackage/>,
    addContentPackage: <AddContentPackage/>,
    editContentPackage: <EditContentPackage/>,
    addCategory: <AddCategory/>,
    editCategory: <EditCategory/>,
    logout: <LogoutConfirmModal/>,
    resetPassword: <ResetPasswordModal/>,
    sendTypeEmail: <SendTypeEmailData/>,
    editUser: <EditUser />,
    addAdmin: <AddAdmin />,
    editAdmin: <EditAdmin />,
    addExercise: <AddExercise />,
    editExercise: <EditExercise />,
}

const Modal = () => {
    const dispatch = useDispatch()
    const type = useSelector( state => state.modal.type )

    useEffect( () => {
        if ( type ) {
            document?.body?.classList?.add( "modalOpen" )
        } else {
            document?.body?.classList?.remove( "modalOpen" )
        }
    }, [ type ] )


    if ( !type ) {
        return <></>
    }


    return <div
        onClick={ () => {
            document.body.classList.remove( "modalOpen" )
            dispatch( setModalType( null ) )
        } }
        className={ styles.main }
    >
        <div className={ styles.wrapper } onClick={ ( e ) => e.stopPropagation() }>
            <div onClick={ () => {
                document.body.classList.remove( "modalOpen" )
                dispatch( setModalType( null ) )
            } } className={ styles.close }><span>X</span></div>
            {
                modalTypes[ type ] || null
            }
        </div>
    </div>
}


export default Modal