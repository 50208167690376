import './App.css';
import { ConfigProvider } from "antd";
import Auth from "./Components/Auth";
import { Route } from "react-router-dom";
import { Routes } from "react-router";
import Main from "./Components/Main";
import VerifyQR from "./Components/QR/verifyQR";
import Modal from "./Components/Modal";
import 'react-quill/dist/quill.snow.css'; // Import Quill's snow theme CSS
import { v4 as uniqId } from "uuid"
import ResetTeacherPassword from "./Components/Auth/Components/resetPassword";


function App() {
    const allRoutes = [
        { id: uniqId(), path: "/auth", element: <Auth/>, name: "Auth" },
        { id: uniqId(), path: "/", element: <Main />, name: "Main" },
        { id: uniqId(), path: "/verify/:token", element: <VerifyQR/>, name: "VerifyQR" },
        { id: uniqId(), path: "/reset/:token", element: <ResetTeacherPassword/>, name: "Reset Password" },
    ]

    return (
        <ConfigProvider
            theme={ {
                token: {
                    colorPrimary: "#4DB671",
                    fontFamily: "Azo-Sans, sans-serif",
                },
            } }
        >
            <Routes>
                { allRoutes.map( ( route ) => {
                    return (
                        <Route key={ route.id } path={ route.path } element={ route.element }/>
                    );
                } ) }
            </Routes>
            <Modal/>
        </ConfigProvider>
    );
}

export default App;
