import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";


export const getLessonsCategories = createAsyncThunk(
    'lessons/getLessonsCategories',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.LessonsCategories( "admin" )
            );
            actions.dispatch( setLoadingState( false ) )
            return response?.data?.categories;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);


export const addCategory = createAsyncThunk(
    'lessons/addLessonsCategories',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            formData.append( "title", data.title )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.LessonsCategories( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully Added",
                } );
            }
            return actions.dispatch( getLessonsCategories() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessonsCategories() )
        }
    }
);

export const editCategory = createAsyncThunk(
    'lessons/editLessonsCategories',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = new FormData()
            formData.append( "id", data.id )
            formData.append( "title", data.title )
            formData.append( "can_show", data.can_show )

            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.LessonsCategories( data.language ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully edited",
                } );
            }
            return actions.dispatch( getLessonsCategories() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessonsCategories() )
        }
    }
);


export const deleteCategory = createAsyncThunk(
    'lessons/deleteLessonsCategories',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.LessonsCategories( data.id ),
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully Deleted",
                } );
            }
            return actions.dispatch( getLessonsCategories() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getLessonsCategories() )
        }
    }
);