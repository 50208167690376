import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Card, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addTeacher } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { GlobalFormItem, globalInputNumberProps } from "../../Common/globalFormItems";

export const AddTeacherData = () => {
    const dispatch = useDispatch()

    const onFinish = ( value ) => {
        dispatch( addTeacher( value ) )
        dispatch( setModalType( null ) )
    };

    return <Card title={ "Add Teacher" }
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <Form name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="name" label="First Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="lastName" label="Last Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="email" label="Email">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="phone" label="Phone">
                <InputNumber { ...globalInputNumberProps } />
            </GlobalFormItem>
            <GlobalFormItem name="position" label="Position">
                <Input/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
