import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import LanguagesHeader from "../Common/languages";
import { getLessonsCategories } from "../../Redux/Slices/Education/Lessons/asyncThunksCategory";
import { Row } from "../Common/tableUtils"


const LessonsCategories = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const categories = useSelector( state => state.lessons.categories )

    useEffect( () => {
        dispatch( getLessonsCategories() )
    }, [ dispatch ] )

    const categoriesColumns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "2",
            title: 'Title',
            dataIndex: "title",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        {
            key: "3",
            title: 'Actions',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editCategory",
                                transfer_id: e.id,
                                process_type: "category",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "category",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ]


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addCategory" ) )
                } }
        >Add Category <ProfileOutlined/> </Button>
        <LanguagesHeader/>
        <SortableContext
            items={ categories.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                rowKey="id"
                columns={ categoriesColumns }
                dataSource={ categories }
            />
        </SortableContext>
    </div>
};
export default LessonsCategories;