import { EditOutlined, FileExcelOutlined, FileSyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import React, { useEffect } from 'react';
import { Badge, Button, Image, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { Row } from "../Common/tableUtils"
import { getAdmins } from "../../Redux/Slices/Admins/asyncThunks";
import Connections from "../../Services/connections";


const expandableColumns = ( dispatch ) => [
    {
        title: 'Phone',
        dataIndex: 'phone',
    },
    {
        title: 'Verified',
        dataIndex: 'verified',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="Verified"/>
            }
            return <Badge status="error" text="Unverified"/>
        }
    },
    {
        title: 'Passed logic test',
        dataIndex: 'passed_test',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="passed test"/>
            }
            return <Badge status="error" text="didn't pass test"/>
        }
    },
    {
        title: 'Physical Cert',
        dataIndex: 'physicalCert',
        render: ( text ) => {
            if ( text ) {
                return <Badge status="success" text="YES"/>
            }
            return <Badge status="error" text="NO"/>
        }
    },
    {
        title: 'Registered',
        dataIndex: 'createdAt',
        key: 'register',
        render: ( text ) => {
            return text.substring( 0, text.indexOf( "T" ) )
        }
    }
];


const Admins = () => {
    const dispatch = useDispatch()
    const admins = useSelector( state => state.admins.all )

    useEffect( () => {
        dispatch( getAdmins() )
    }, [ dispatch ] )


    const columns = [
        // {
        //     key: 'sort',
        // },
        {
            title: 'Photo',
            dataIndex: 'photo',
            render: ( photo ) => {
                return <Image
                    src={ Connections.GetImage( photo ) }
                    width="35px"
                    height="35px"
                    alt="avatar"
                    style={ { width: '100%' } }
                />
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'LastName',
            dataIndex: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Role',
            render: ( record ) => {
                return record?.password?.role || "Empty"
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            render: ( data ) => {
                return <>
                    <EditOutlined
                        style={ { fontSize: "20px", cursor: "pointer" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editAdmin",
                                transfer_id: data.id,
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return (
        <DndContext modifiers={ [ restrictToVerticalAxis ] }>
            <Button
                onClick={ () => dispatch( setModalType( "addAdmin" ) ) }
                type="primary"
                style={ { margin: "10px 0 20px 5px" } }
            >Add Admin <UserAddOutlined/> </Button>
            <SortableContext
                items={ admins?.map( ( i ) => i.id ) || [] }
                // strategy={ verticalListSortingStrategy }
            >
                <Table
                    components={ {
                        body: {
                            row: Row,
                        },
                    } }
                    expandable={ {
                        expandedRowRender: ( record ) => {
                            return <Table
                                rowKey="id"
                                columns={ expandableColumns( dispatch ) }
                                dataSource={ [ { ...record } ] }
                                pagination={ false }
                            />;
                        },
                    } }
                    rowSelection={ {
                        type: "checkbox",
                        // ...rowSelection,
                    } }
                    rowKey="id"
                    columns={ columns }
                    dataSource={ admins || [] }
                />
            </SortableContext>
        </DndContext>
    );
};
export default Admins;