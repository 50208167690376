import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../fetch";
import Connection from "../../../Services/connections";
import { setLoadingState } from "../loading";
import { notification } from "antd";
import { handlerFormData } from "../../../Components/TimeLine/components/utils";

export const getAdmins = createAsyncThunk(
    'admins/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.Admins(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.all;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);

export const addAdmin = createAsyncThunk(
    'admins/addAdmin',
    async ( { data, image }, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = handlerFormData( data, [ "image" ] )
            if ( image ) {
                formData.append( "file", image )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.AddAdmin(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }
            return actions.dispatch( getAdmins() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getAdmins() )
        }
    }
);

export const editAdmin = createAsyncThunk(
    'admins/editAdmin',
    async ( { data, image }, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = handlerFormData( data, [ "image", "id" ] )
            if ( image ) {
                formData.append( "file", image )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EditAdmin( data.id, "admin" ),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
                return actions.dispatch( getAdmins() )
            }
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getAdmins() )
        }
    }
);