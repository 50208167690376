import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editCustomer } from "../../../Redux/Slices/Customer/asyncThunks";
import { GlobalFormItem } from "../../Common/globalFormItems";
import { compareObjects, handlerMutableObject } from "../../TimeLine/components/utils";


export const EditCustomerData = () => {
    const dispatch = useDispatch()
    const customers = useSelector( state => state.customers.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_customer = customers?.filter( customer => customer.id === transfer_id )[ 0 ]
    const customerStatusOption = [
        {
            key: "waiting",
            label: "Waiting",
            value: "waiting"
        },
        {
            key: "connected",
            label: "Connected",
            value: "connected"
        },
        {
            key: "finished",
            label: "Finished",
            value: "finished"
        },
    ]

    const onFinish = ( value ) => {
        const status = value?.status?.value
        let data = handlerMutableObject( value, [ "status" ] )
        if ( status?.toLowerCase() !== selected_customer?.status?.toLowerCase() ) {
            data.status = status
        }

        data = compareObjects( data, selected_customer )

        if ( Object.keys( data ).length ) {
            data.id = transfer_id
            dispatch( editCustomer( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit Customer ID: ${ transfer_id }` }
                 style={ {
                     width: 570,
                     textAlign: "center",
                 } }>
        <Form
            initialValues={ {
                "name": selected_customer.name,
                "lastName": selected_customer.lastName,
                "email": selected_customer.email,
                "phone": parseInt( selected_customer.phone ),
                "contactType": selected_customer.contactType,
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }
        >
            <GlobalFormItem name="name" label="First Name">
                <Input disabled={ selected_customer.status === "finished" }/>
            </GlobalFormItem>
            <GlobalFormItem name="lastName" label="Last Name">
                <Input disabled={ selected_customer.status === "finished" }/>
            </GlobalFormItem>
            <GlobalFormItem name="email" label="Email">
                <Input disabled={ selected_customer.status === "finished" }/>
            </GlobalFormItem>
            <GlobalFormItem name="phone" label="Phone">
                <InputNumber
                    disabled={ selected_customer.status === "finished" }
                    minLength={ 8 }
                    maxLength={ 9 }
                    style={ { width: "100%" } }
                />
            </GlobalFormItem>
            <GlobalFormItem name="contactType" label="Contact Type">
                <Input disabled={ selected_customer.status === "finished" }/>
            </GlobalFormItem>
            <GlobalFormItem name="status" label="Status">
                <Select
                    labelInValue
                    allowClear
                    disabled={ selected_customer?.status?.toLowerCase() === "finished" }
                    style={ {
                        position: "relative",
                        margin: "15px 0",
                        width: '100%',
                    } }
                    placeholder={
                        selected_customer?.status?.charAt( 0 ).toUpperCase() + selected_customer?.status?.slice( 1 )
                    }
                    options={ customerStatusOption }
                />
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button
                    disabled={ selected_customer?.status === "finished" }
                    type="primary" htmlType="submit"
                >
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
