import React, { useEffect } from 'react';
import style from './styles/homeworks.module.scss';
import { v4 as uuidV4 } from "uuid"
import { Badge, Image, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Row } from "../../Common/tableUtils"
import Connections from "../../../Services/connections";
import { handlerDateAndTime } from "../../TimeLine/components/utils";
import { getExamResult } from "../../../Redux/Slices/Education/ExamResults/asyncThunks";

const ExamResults = () => {
    const dispatch = useDispatch()
    const selectedLanguage = useSelector( state => state.languages.selected )
    const examResults = useSelector( state => state.examResults.all )


    useEffect( () => {
        dispatch( getExamResult() )
    }, [ dispatch ] )

    const expandableHomeworkColumns = [

        {
            key: uuidV4(),
            title: "Homework status",
            dataIndex: "isComplete",
            render: ( record ) => {
                return <Badge status={ record ? "success" : "error" } text={ record ? "Yes" : "No" }/>
            }
        },
        {
            key: uuidV4(),
            title: 'Additional',
            dataIndex: "isAdditional",
            render: ( record ) => {
                return <Badge status={ record ? "success" : "error" } text={ record ? "Yes" : "No" }/>
            }
        },
        {
            key: uuidV4(),
            title: 'Completed Status',
            dataIndex: "completedStatus",
            render: ( record ) => {
                return record || 0 + " %" || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: 'Homework files',
            dataIndex: "homeworkFile",
            render: ( record ) => {
                const images = record?.split( "," )
                return <div style={ { width: '100%', gap: "15px", display: "flex", flexWrap: "wrap" } }>
                    {
                        images?.map( image => {
                            return <div key={ uuidV4() }>
                                <Image
                                    src={ Connections.GetImage( image ) }
                                    width="35px"
                                    height="35px"
                                    alt="avatar"
                                />
                            </div>
                        } )
                    }
                </div>
            }
        },
        {
            key: uuidV4(),
            title: 'Exercise title',
            render: ( data ) => {
                console.log( data, "<data" )
                return data?.exercise?.title[ selectedLanguage ] || <b><i>Empty</i></b>
            }
        },

    ];

    const expandableExamsColumns = [

        {
            key: uuidV4(),
            title: "Photo",
            dataIndex: "exam",
            render: ( exam ) => {
                return exam?.icon ?
                    <Image
                        src={ Connections.GetImage( exam.icon ) }
                        width="35px"
                        height="35px"
                        alt={ exam.name }
                        style={ { width: '100%' } }
                    />
                    : <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Exam",
            dataIndex: "exam",
            render: ( exam ) => {
                return exam?.name || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Level",
            dataIndex: "exam",
            render: ( exam ) => {
                return exam?.level || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "ExamStatus status",
            dataIndex: "completedStatus",
            render: ( record ) => {
                return record ? `${ record || 0 } %` : <Badge status={ "error" } text="Need to complete"/>
            }
        },
        {
            key: uuidV4(),
            title: 'Additional',
            dataIndex: "isAdditional",
            render: ( record ) => {
                return <Badge status={ record ? "success" : "error" } text={ record ? "Yes" : "No" }/>
            }
        },
        {
            key: uuidV4(),
            title: 'Correct answers',
            dataIndex: "correctAnswers",
            render: ( record ) => {
                return record || <b><i>Empty</i></b>
            }
        },

        {
            key: uuidV4(),
            title: 'Last update',
            dataIndex: "updatedAt",
            render: ( record ) => {
                return `${ handlerDateAndTime( record ) }` || <b><i>Empty</i></b>
            }
        },

    ];

    const studentScoreColumns = [
        {
            key: uuidV4(),
            title: "Score",
            dataIndex: "ratedPercent",
            render: ( text ) => {
                return `${ text || 0 } %` || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Exams",
            dataIndex: "examCounts",
            render: ( text ) => {
                return text || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Homeworks",
            dataIndex: "examCounts",
            render: ( text ) => {
                return text || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Additional exams",
            dataIndex: "additionalExamsCount",
            render: ( text ) => {
                return text || <b><i>Empty</i></b>
            }
        },
        {
            key: uuidV4(),
            title: "Additional homeworks",
            dataIndex: "additionalHomeworksCount",
            render: ( text ) => {
                return text || <b><i>Empty</i></b>
            }
        }
    ];

    const columns = [
        {
            key: uuidV4(),
            title: "ID",
            dataIndex: "id"
        },
        {
            key: uuidV4(),
            title: "Group",
            render: ( data ) => {
                return data?.group?.name
            }
        },
        {
            key: uuidV4(),
            title: "Photo",
            dataIndex: "photo",
            render: ( photo ) => {
                return <Image
                    src={ Connections.GetImage( photo || "default_user.png" ) }
                    width="35px"
                    height="35px"
                    alt="user"
                />
            }
        },
        {
            key: uuidV4(),
            title: "Student",
            render: ( data ) => {
                return data?.name
            }
        },
        {
            key: uuidV4(),
            title: "Lastname",
            render: ( data ) => {
                return data?.lastName
            }
        },
    ];

    return <div className={ style.contentContainer }>
        <Table
            components={ {
                body: {
                    row: Row,
                },
            } }
            expandable={ {
                expandedRowRender: ( record ) => {
                    return <div key={ uuidV4() }>
                        <h1 style={ { textAlign: "center", color: "#4DB671" } }>Score</h1>
                        <Table
                            rowKey="score"
                            columns={ studentScoreColumns }
                            dataSource={ [ record.studentScore ] || [] }
                            pagination={ true }
                        />
                        <h1 style={ { textAlign: "center", color: "#4DB671" } }>Homeworks</h1>
                        <Table
                            rowKey="homeworks"
                            columns={ expandableHomeworkColumns }
                            dataSource={ record.homeworks || [] }
                            pagination={ true }
                        />
                        <h1 style={ { textAlign: "center", color: "#4DB671" } }>Exams Results</h1>
                        <Table
                            rowKey="exams"
                            columns={ expandableExamsColumns }
                            dataSource={ record.examsResults || [] }
                            pagination={ true }
                        />
                    </div>
                },
            } }
            rowKey="id"
            columns={ columns }
            dataSource={ examResults }
        />
    </div>
};
export default ExamResults;