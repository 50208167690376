import React, { useEffect } from 'react';
import styles from './styles/user.module.scss';
import Connection from "../../Services/connections";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../Redux/Slices/Auth/asyncThunk";
import { useNavigate } from "react-router";
import { Card, Image, Typography } from "antd";
import { setModalType } from "../../Redux/Slices/modal";
import { EditOutlined } from "@ant-design/icons";
import { handlerDateAndTime } from "../TimeLine/components/utils";

const { Title, Paragraph } = Typography;


const UserInfo = () => {
    const user = useSelector( state => state.authentication?.user )
    const permissions = useSelector( state => state.authentication?.permissions )
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect( () => {
        if ( !user.name || !permissions ) {
            dispatch( getUserData( navigate ) )
        }
    }, [ dispatch, user, permissions ] )


    return <div className={ styles.main }>
        <Card
            title={ user?.createdAt?.substring( 0, user?.createdAt?.indexOf( "T" ) ) }
            extra={
                <EditOutlined
                    onClick={ () => dispatch( setModalType( "editUser" ) ) }
                />
            }
            style={ {
                // width: "100%",
                // height: "100%",
                marginTop: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            } }>
            <div className={ styles.card_container }>
                <div className={ styles.avatar_container }>
                    <Image
                        className={ styles.avatar }
                        src={ Connection.GetImage( user.photo ) }/>
                </div>
                <div className={ styles.info_container }>
                    <div style={ { display: "flex", flexDirection: "column" } }>
                        <Title level={ 4 }>{ user?.name } { user?.lastName }</Title>
                        <Paragraph
                            copyable={ { text: user?.email } }>
                            <a href={ `mailto:${ user?.email }` }
                               style={ { color: "black", textDecoration: "none", fontSize: "15px" } }>
                                { user?.email }
                            </a>
                        </Paragraph>
                        {
                            user?.phone ?
                                <a href={ `tel:0${ user.phone }` }
                                   style={ {
                                       color: "black",
                                       textDecoration: "none",
                                       fontSize: "15px"
                                   } }>
                                    +374 { user.phone }
                                </a> : <></>
                        }
                    </div>
                </div>
            </div>

        </Card>
    </div>
};
export default UserInfo;