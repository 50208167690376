import { createSlice } from '@reduxjs/toolkit'
import { getAllEmailTypes } from "./asyncThunksCategory";


export const emailTypes = createSlice( {
    name: 'emailTypes',
    initialState: {
        all: [],
        selected: null
    },
    reducers: {
        chooseEmailType: ( state, action ) => {
            state.selected = action.payload
        }
    },
    extraReducers: ( builder ) => {
        builder
            .addCase( getAllEmailTypes.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

export const {chooseEmailType} = emailTypes.actions
export default emailTypes.reducer