import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Card, Image } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { TableBuilder } from "./tableBuilder";
import { getExams } from "../../Redux/Slices/Education/Exams/asyncThunk";
import LanguagesHeader from "../Common/languages";
import Connections from "../../Services/connections";

const tabList = [
    {
        key: 'all',
        tab: 'All',
    },
    {
        key: 'logical',
        tab: 'Logical',
    },
    {
        key: 'html',
        tab: 'Html',
    },
    {
        key: 'css',
        tab: 'Css',
    },
    {
        key: 'javascript',
        tab: 'JavaScript',
    },
];

const expandableAnswersColumns = language => [
    {
        key: "11",
        title: 'Answer ID',
        dataIndex: 'id'
    },
    {
        key: "12",
        title: 'Correct',
        render: ( record ) => {
            return record?.correct_answer
        }
    },
    {
        key: "13",
        title: 'Answer A',
        render: ( record ) => {
            return record.answer_a[ language ]
        }
    },
    {
        key: "14",
        title: 'Answer B',
        render: ( record ) => {
            return record.answer_b[ language ]
        }
    },
    {
        key: "15",
        title: 'Answer C',
        render: ( record ) => {
            return record.answer_c[ language ]
        }
    },
    {
        key: "16",
        title: 'Answer D',
        render: ( record ) => {
            return record.answer_d[ language ]
        }
    },
];


const Exam = () => {
    const dispatch = useDispatch()
    const exams = useSelector( state => state.exams.all )
    const language = useSelector( state => state.languages.selected )

    useEffect( () => {
        dispatch( getExams() )
    }, [ dispatch ] )


    const [ activeTabKey1, setActiveTabKey1 ] = useState( 'all' );

    const onTab1Change = ( key ) => {
        setActiveTabKey1( key );
    };

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Exam',
            dataIndex: "name"
        },
        {
            key: "2",
            title: 'Icon',
            dataIndex: "icon",
            render: ( icon ) => {
                return icon ? <Image
                    src={ Connections.GetImage( icon ) }
                    width="20px"
                    height="20px"
                    alt="avatar"
                    style={ { width: '100%' } }
                /> : <p>-</p>
            }
        },
        {
            key: "3",
            title: 'Level',
            dataIndex: 'level'
        },
        {
            key: "4",
            title: 'Duration',
            dataIndex: 'duration'
        },
        {
            key: "5",
            title: 'Format',
            dataIndex: 'duration_format'
        },
        {
            key: "6",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editExam",
                                transfer_id: e.id,
                                process_type: "examInfo",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "exam",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    const expandableColumns = language => [
        {
            key: "7",
            title: 'Question ID',
            dataIndex: 'id'
        },
        {
            key: "8",
            title: 'Title',
            render: ( record ) => {
                return record.title[ language ]
            }
        },
        {
            key: "9",
            title: 'Question',
            render: ( record ) => {
                return record.question[ language ]
            }
        },
        {
            key: "10",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editExam",
                                transfer_id: e.examId,
                                process_type: "examQuestion",
                                processed_item_id: e.id
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "question",
                            } ) )
                        } }
                    />
                </>
            }
        },

    ];

    const contentList = {
        all: <TableBuilder
            tableData={ exams }
            columns={ columns }
            expandableName={ "questions" }
            expandableColumns={ expandableColumns( language ) }
            expandableAnswers={ "answers" }
            expandableAnswersColumns={ expandableAnswersColumns( language ) }
        />,
        logical: <TableBuilder
            tableData={ exams.filter( exam => exam.name.toLowerCase() === "logical" ) }
            columns={ columns } expandableName={ "questions" }
            expandableColumns={ expandableColumns( language ) }
            expandableAnswers={ "answers" }
            expandableAnswersColumns={ expandableAnswersColumns( language ) }
        />,
        html: <TableBuilder
            tableData={ exams.filter( exam => exam.name.toLowerCase() === "html" ) } columns={ columns }
            expandableName={ "questions" } expandableColumns={ expandableColumns( language ) }
            expandableAnswers={ "answers" }
            expandableAnswersColumns={ expandableAnswersColumns( language ) }
        />,
        css: <TableBuilder
            tableData={ exams.filter( exam => exam.name.toLowerCase() === "css" ) } columns={ columns }
            expandableName={ "questions" } expandableColumns={ expandableColumns( language ) }
            expandableAnswers={ "answers" }
            expandableAnswersColumns={ expandableAnswersColumns( language ) }
        />,
        javascript: <TableBuilder
            tableData={ exams.filter( exam => exam.name.toLowerCase() === "javascript" ) }
            columns={ columns }
            expandableName={ "questions" } expandableColumns={ expandableColumns( language ) }
            expandableAnswers={ "answers" }
            expandableAnswersColumns={ expandableAnswersColumns( language ) }
        />,
    };


    return <Card
        tabList={ tabList }
        activeTabKey={ activeTabKey1 }
        onTabChange={ onTab1Change }
        style={ { width: "100%", minHeight: "100vh" } }
    >
        <Button
            onClick={ () => dispatch( setModalType( "addExam" ) ) }
            type="primary"
            style={ { margin: "10px 0 20px 5px" } }
        >Add Exam <ReadOutlined/></Button>
        <LanguagesHeader/>
        { contentList[ activeTabKey1 ] }
    </Card>
};
export default Exam;