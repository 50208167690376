import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";
import { handlerFormData } from "../../../../Components/TimeLine/components/utils";


export const getAllEmailTypes = createAsyncThunk(
    'emailTypes/getAllEmailTypes',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.EmailTypes()
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.emails;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);


export const sendTypeEmail = createAsyncThunk(
    'emailTypes/sendTypeEmail',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = handlerFormData( data )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EmailTypes(),
                formData
            );

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Successfully sent",
                } );
            }
            actions.dispatch( setLoadingState( false ) )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getAllEmailTypes() )
        }
    }
);