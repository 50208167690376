import React from "react";
import { Button, Card } from "antd";
import { setModalType } from "../../../Redux/Slices/modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dataControl from "../../../Services/dataControl";
import { resetUserPassword } from "../../../Redux/Slices/Auth/asyncThunk";


export const ResetPasswordModal = () => {
    const dispatch = useDispatch()
    const user = useSelector( state => state.authentication?.user )
    const language = useSelector( state => state.languages.selected )
    const navigate = useNavigate()


    return <Card title="Are you sure to reset your password?"
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>

        <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
            <Button
                type="default"
                onClick={ () => dispatch( setModalType( null ) ) }
                style={ { marginRight: "30px" } }
            >Cancel</Button>
            <Button
                type="primary"
                onClick={ () => {
                    dataControl.removeToken()
                    dispatch( resetUserPassword( { data: { email: user.email, language }, navigate } ) )
                    dispatch( setModalType( null ) )
                    navigate( "/auth" )
                } }
            >Yes</Button>
        </div>
    </Card>
}

