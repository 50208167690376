import { v4 as uuidV4 } from "uuid"

export const recordFormatsOfDate = ( value ) => {
    if ( value < 10 ) {
        return `0${ value }`
    }
    return value
}


export const handlerMutableObject = ( data, without = [], includeId = false ) => {
    const isSameWhit = {}
    without.forEach( item => {
        if ( item ) {
            isSameWhit[ item ] = true
        }
    } )
    const mutableObject = {}
    for ( let prop in data ) {
        const withId = includeId ? true : prop !== "id"
        if ( withId && data[ prop ] && !isSameWhit[ prop ] ) {
            mutableObject[ prop ] = data[ prop ]
        }
    }
    return mutableObject
}

export const handlerFormData = ( data, without = [], can_be = true ) => {
    const formData = new FormData()
    const isSameWith = {}
    without.forEach( item => {
        if ( item ) {
            isSameWith[ item ] = true
        }
    } )
    for ( let prop in data ) {
        if ( ( data[ prop ] || data[ prop ] === can_be ) && !isSameWith[ prop ] ) {
            formData.append( prop, data[ prop ] )
        }
    }
    return formData
}

export const compareObjects = ( data, comparableData, without = [] ) => {
    // need to add languages logic
    const isSameWith = {}
    without.forEach( item => {
        if ( item ) {
            isSameWith[ item ] = true
        }
    } )
    const difference = {}
    for ( let prop in data ) {
        if ( data[ prop ] !== comparableData[ prop ] && data[ prop ] && data[ prop ] && prop !== "phone" ) {
            difference[ prop ] = data[ prop ]
        }
        if ( prop === "phone" && data[ prop ].toString() !== comparableData[ prop ].toString() && data[ prop ] ) {
            difference[ prop ] = data[ prop ]
        }
    }
    return difference
}

export const handlerDateAndTime = ( date, whitClock = true ) => {
    const day = new Date( date ).getUTCDate(),
        month = new Date( date ).getUTCMonth(),
        year = new Date( date ).getUTCFullYear();
    let hour = new Date( date ).getHours(),
        minutes = new Date( date ).getMinutes(),
        seconds = new Date( date ).getSeconds();

    return `${ recordFormatsOfDate( day ) }/${ recordFormatsOfDate( month ) }/${ recordFormatsOfDate( year ) } ${ whitClock ? `in ${ recordFormatsOfDate( hour ) }:${ recordFormatsOfDate( minutes ) }:${ recordFormatsOfDate( seconds ) }` : "" }`
}

export const weekDaysOption = [
    { key: uuidV4(), label: "Monday", value: "Monday" },
    { key: uuidV4(), label: "Tuesday", value: "Tuesday" },
    { key: uuidV4(), label: "Wednesday", value: "Wednesday" },
    { key: uuidV4(), label: "Thursday", value: "Thursday" },
    { key: uuidV4(), label: "Friday", value: "Friday" },
    { key: uuidV4(), label: "Saturday", value: "Saturday" },
    { key: uuidV4(), label: "Sunday", value: "Sunday" },
]

export const startEndOption = [
    { key: uuidV4(), label: "13:00 && 15:00", value: "13:00,15:00" },
    { key: uuidV4(), label: "15:00 && 17:00", value: "15:00,17:00" },
    { key: uuidV4(), label: "17:00 && 19:00", value: "17:00,19:00" },
    { key: uuidV4(), label: "19:00 && 21:00", value: "19:00,21:00" },
]

export const durationOption = [
    { key: uuidV4(), label: "25m", value: "25m" },
    { key: uuidV4(), label: "30m", value: "30m" },
    { key: uuidV4(), label: "45m", value: "45m" },
    { key: uuidV4(), label: "1h", value: "1h" },
    { key: uuidV4(), label: "2h", value: "2h" },
    { key: uuidV4(), label: "3h", value: "3h" },
    { key: uuidV4(), label: "4h", value: "4h" },
    { key: uuidV4(), label: "5h", value: "5h" },
]


export const classroomsOptions = [
    { label: "Classroom 1", value: "Classroom 1" },
    { label: "Classroom 2", value: "Classroom 2" },
]


export const dummyRequest = ( { file, onSuccess } ) => {
    setTimeout( () => {
        onSuccess( "ok" );
    }, 0 );
};
