import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select } from 'antd';
import { setModalType } from "../../../../Redux/Slices/modal";
import { GlobalFormItem } from "../../../Common/globalFormItems";
import { addExercise } from "../../../../Redux/Slices/Education/Exercises/asyncThunk";
import { handlerMutableObject } from "../../../TimeLine/components/utils";
import { getLessons } from "../../../../Redux/Slices/Education/Lessons/asyncThunks";
import { v4 as uuidV4 } from "uuid";


export const AddExercise = () => {
    const dispatch = useDispatch()
    const language = useSelector( state => state.languages.selected )
    const lessons = useSelector( state => state.lessons.all )


    useEffect( () => {
        if ( !lessons.length ) {
            dispatch( getLessons() )
        }
    }, [ dispatch, lessons ] )

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )

        if ( Object.keys( data ).length ) {
            data.language = language
            dispatch( addExercise( data ) )
        }

        dispatch( setModalType( null ) )
    };

    const lessonsOptions = []
    lessons?.forEach( lesson => {
        if ( Array.isArray( lesson.sub_lessons ) ) {
            lesson.sub_lessons.forEach( sub_lesson => {
                lessonsOptions.push( {
                    key: uuidV4(),
                    label: `${ lesson.name } || ${ sub_lesson.title[ language ] }`,
                    value: sub_lesson.id,
                } )
            } )
        }
    } )


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New exercise language:${ language.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="subLessonId" label="Sub Lessons">
                <Select
                    // mode=""
                    style={ {
                        position: "relative",
                        width: '100%',
                        margin: "10px 0 20px 0"
                    } }
                    placeholder="Lessons"
                    optionFilterProp="label"
                    showSearch={ true }
                    options={ lessonsOptions }
                />
            </GlobalFormItem>

            <GlobalFormItem name="title" label="Exercises title">
                <Input.TextArea rows={ 3 }/>
            </GlobalFormItem>

            <GlobalFormItem name="description" label="Description">
                <Input.TextArea rows={ 5 }/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}


export default AddExercise