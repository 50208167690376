import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card } from "antd";
import { setModalType } from "../../../Redux/Slices/modal";
import { deleteStudents } from "../../../Redux/Slices/Education/Students/asyncThunks";
import { deleteQr } from "../../../Redux/Slices/Education/QR/asyncThunks";
import {
    deleteContent,
    deleteDescriptionContent,
    deleteMoreItemContent
} from "../../../Redux/Slices/Content/asyncThunk";
import { deleteTeacher } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { deleteExams, deleteQuestion } from "../../../Redux/Slices/Education/Exams/asyncThunk";
import { deleteGroup } from "../../../Redux/Slices/Education/Group/asyncThunk";
import { deleteTimeLines } from "../../../Redux/Slices/Education/TimeLine/asyncThunks";
import { deleteLessons } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { deleteSubLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunksSubLessons";
import { deletePackage } from "../../../Redux/Slices/Education/Packages/asyncThunks";
import { deletePackageExemplar } from "../../../Redux/Slices/ContentPackages/asyncThunk";
import { deleteCustomer } from "../../../Redux/Slices/Customer/asyncThunks";
import { deleteCategory } from "../../../Redux/Slices/Education/Lessons/asyncThunksCategory";
import { deleteExercise } from "../../../Redux/Slices/Education/Exercises/asyncThunk";


const handlerConfirmButton = ( process_type, transfer_id, dispatch ) => {
    if ( process_type === "qr" ) {
        dispatch( deleteQr( { id: transfer_id } ) )
    }
    if ( process_type === "student" ) {
        dispatch( deleteStudents( { id: transfer_id } ) )
    }

    if ( process_type === "content" ) {
        dispatch( deleteContent( { id: transfer_id } ) )
    }

    if ( process_type === "teacher" ) {
        dispatch( deleteTeacher( { id: transfer_id } ) )
    }

    if ( process_type === "exam" ) {
        dispatch( deleteExams( { id: transfer_id } ) )
    }

    if ( process_type === "question" ) {
        dispatch( deleteQuestion( { id: transfer_id } ) )
    }

    if ( process_type === "group" ) {
        dispatch( deleteGroup( { id: transfer_id } ) )
    }

    if ( process_type === "timeLine" ) {
        dispatch( deleteTimeLines( { id: transfer_id } ) )
    }

    if ( process_type === "lessons" ) {
        dispatch( deleteLessons( { id: transfer_id } ) )
    }

    if ( process_type === "subLessons" ) {
        dispatch( deleteSubLesson( { id: transfer_id } ) )
    }

    if ( process_type === "packages" ) {
        dispatch( deletePackage( { id: transfer_id } ) )
    }

    if ( process_type === "exercise" ) {
        dispatch( deleteExercise( { id: transfer_id } ) )
    }

    if ( process_type === "customer" ) {
        dispatch( deleteCustomer( { id: transfer_id } ) )
    }

    if ( process_type === "category" ) {
        dispatch( deleteCategory( { id: transfer_id } ) )
    }

    if ( process_type === "packagesExemplar" ) {
        dispatch( deletePackageExemplar( { id: transfer_id } ) )
    }

    if ( process_type.indexOf( "contentDescription" ) >= 0 ) {
        const page_id = process_type.substring( "contentDescription-".length )
        dispatch( deleteDescriptionContent( { page_id, description_id: transfer_id } ) )
    }

    if ( process_type.indexOf( "contentMoreItem" ) >= 0 ) {
        const page_id = process_type.substring( "contentMoreItem-".length )
        dispatch( deleteMoreItemContent( { page_id, more_item_id: transfer_id } ) )
    }

    dispatch( setModalType( null ) )
}


const DeleteModal = () => {
    const dispatch = useDispatch()
    const process_type = useSelector( state => state.modal.process_type )
    const transfer_id = useSelector( state => state.modal.transfer_id )


    return <Card title="Are you sure to delete?"
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>

        <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
            <Button
                type="default"
                onClick={ () => {
                    dispatch( setModalType( null ) )
                } }
                style={ { marginRight: "30px" } }
            >Cancel</Button>
            <Button
                type="primary"
                onClick={ () => handlerConfirmButton( process_type, transfer_id, dispatch ) }
            >Confirm</Button>
        </div>
    </Card>
}

export default DeleteModal