import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { editTeacher } from "../../../Redux/Slices/Education/Teachers/asyncThunks";
import { getPackages } from "../../../Redux/Slices/Education/Packages/asyncThunks";
import { GlobalFormItem, globalInputNumberProps } from "../../Common/globalFormItems";


export const EditTeacherData = () => {
    const dispatch = useDispatch()
    const teachers = useSelector( state => state.teachers.all )
    const packages = useSelector( state => state.packages.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const selected_teacher = teachers.filter( student => student.id === transfer_id )[ 0 ]
    const [ changedPackageID ] = useState( null )


    useEffect( () => {
        if ( !packages.length ) {
            dispatch( getPackages() )
        }
    }, [ dispatch, packages.length ] )


    const onFinish = ( value ) => {
        let data = {}
        for ( let prop in value ) {
            if ( prop === "phone" ) {
                if ( value[ "phone" ] !== parseInt( selected_teacher[ "phone" ] ) ) {
                    data[ "phone" ] = value[ "phone" ]
                }
            }
            if ( value[ prop ] !== selected_teacher[ prop ] && prop !== "phone" ) {
                data[ prop ] = value[ prop ]
            }
        }

        if ( Object.keys( data ).length !== 0 ) {
            data.id = transfer_id
            if ( changedPackageID ) {
                data.packageId = changedPackageID
            }
            dispatch( editTeacher( data ) )
            dispatch( setModalType( null ) )
        }
    };

    return <Card title={ `Edit teacher ID: ${ transfer_id }` }
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }
            initialValues={ {
                "name": selected_teacher.name || "",
                "lastName": selected_teacher.lastName,
                "email": selected_teacher.email,
                "phone": parseInt( selected_teacher.phone ),
                "position": selected_teacher.position,
            } }
        >
            <GlobalFormItem isRequired={false} name="name" label="First Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={false} name="lastName" label="Last Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={false} name="email" label="Email">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={false} name="phone" label="Phone">
                <InputNumber { ...globalInputNumberProps }/>
            </GlobalFormItem>
            <GlobalFormItem isRequired={false}  name="position" label="Position">
                <Input/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </div>
        </Form>
    </Card>
}
