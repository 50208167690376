import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Radio, Upload, InputNumber } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { addExams } from "../../../Redux/Slices/Education/Exams/asyncThunk";
import {
    globalBeforeUpload,
    GlobalFormItem,
    globalGetBase64,
    globalInputNumberProps,
    GlobalUploadButton
} from "../../Common/globalFormItems";
import { dummyRequest, handlerMutableObject } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const AddExamData = () => {
    const dispatch = useDispatch()
    const langId = useSelector( state => state.languages.selected )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value, [ "icon" ] )
        if ( value.icon ) {
            data.icon = value.icon?.file?.originFileObj
        }
        dispatch( addExams( { ...data, language: langId } ) )
        dispatch( setModalType( null ) )
    };

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Exam language -> ${ langId }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="type" label="Type">
                <Input placeholder="CSS"/>
            </GlobalFormItem>
            <GlobalFormItem name="name" label="Exam">
                <Input placeholder="JavaScript"/>
            </GlobalFormItem>
            <GlobalFormItem name="level" label="Level">
                <Input placeholder="Easy"/>
            </GlobalFormItem>
            <GlobalFormItem name="icon" label="Icon">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    customRequest={ dummyRequest }
                    accept="image/*"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/> :
                        <GlobalUploadButton loading={ loading }/>
                    }
                </Upload>
            </GlobalFormItem>
            <GlobalFormItem name="duration" label="Duration">
                <InputNumber { ...globalInputNumberProps } minLength={ 1 } maxLength={ 3 } placeholder="30"/>
            </GlobalFormItem>
            <GlobalFormItem name="duration_format" label="Format">
                <Input placeholder="minutes"/>
            </GlobalFormItem>

            <GlobalFormItem name="title" label="title">
                <Input placeholder="Ex. Question 1"/>
            </GlobalFormItem>
            <GlobalFormItem name="question" label="Question">
                <TextArea rows={ 3 } placeholder="Write your question here."/>
            </GlobalFormItem>

            <GlobalFormItem label="Correct Answer" name="correct_answer">
                <Radio.Group>
                    <Radio.Button value="answer_a">A</Radio.Button>
                    <Radio.Button value="answer_b">B</Radio.Button>
                    <Radio.Button value="answer_c">C</Radio.Button>
                    <Radio.Button value="answer_d">D</Radio.Button>
                </Radio.Group>
            </GlobalFormItem>
            <GlobalFormItem name="answer_a" label="Answer_a">
                <Input placeholder="A) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem name="answer_b" label="Answer_b">
                <Input placeholder="B) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem name="answer_c" label="Answer_c">
                <Input placeholder="C) Your answer here"/>
            </GlobalFormItem>
            <GlobalFormItem name="answer_d" label="Answer_d">
                <Input placeholder="D) Your answer here"/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
