import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { sendTypeEmail } from "../../../Redux/Slices/Education/Emails/asyncThunksCategory";
import { handlerMutableObject } from "../../TimeLine/components/utils";
import { GlobalFormItem } from "../../Common/globalFormItems";

export const SendTypeEmailData = () => {
    const dispatch = useDispatch()
    const emailType = useSelector( state => state.emailTypes.selected )

    const onFinish = ( value ) => {
        const data = handlerMutableObject( value )
        if ( Object.keys( data ).length ) {
            emailType && dispatch( sendTypeEmail( { ...value, type: emailType?.name } ) )
        }
        dispatch( setModalType( null ) )
    };

    return <Card title={ `Send email ${ emailType && emailType?.label }` }
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <Form
            initialValues={ {
                "name": "Genie",
                "lastName": "Web",
                "email": "genieweb.llc@gmail.com",
            } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            <GlobalFormItem name="name" label="First Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="lastName" label="Last Name">
                <Input/>
            </GlobalFormItem>
            <GlobalFormItem name="email" label="Email">
                <Input/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Send
                </Button>
            </div>
        </Form>
    </Card>
}
