import {
    DeleteOutlined, HistoryOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect } from 'react';
import { Badge, Table, Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setStudentsDataSource } from "../../Redux/Slices/Education/Students";
import { getQrs, printQr } from "../../Redux/Slices/Education/QR/asyncThunks";
import Connections from "../../Services/connections";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import { Row, rowSelection } from "../Common/tableUtils"
import { handlerDateAndTime } from "../TimeLine/components/utils";


const expandableColumns = [
    {
        key: "5",
        title: 'Scan status',
        dataIndex: 'is_scanned',
        render: ( is_scanned ) => {
            if ( is_scanned ) {
                return <Badge status="success" text="Scanned"/>
            }
            return <Badge status="error" text="No"/>
        }
    },
    {
        key: "6",
        title: 'Print status',
        dataIndex: 'printed',
        render: ( printed ) => {
            if ( printed ) {
                return <Badge status="success" text="Printed"/>
            }
            return <Badge status="error" text="No"/>
        }
    },
    {
        key: "7",
        title: 'Unique Id',
        dataIndex: 'unique_id',
    },
    {
        key: "8",
        title: 'Created',
        dataIndex: 'createdAt',
        render: ( record ) => {
            return handlerDateAndTime( record )
        }
    },
    {
        key: "85",
        title: 'UpdatedAt',
        dataIndex: 'updatedAt',
        render: ( record ) => {
            return handlerDateAndTime( record )
        }
    },
];

const Qr = () => {
    const dispatch = useDispatch()
    const qrs = useSelector( state => state.qr.qrs )


    useEffect( () => {
        dispatch( getQrs() )
    }, [ dispatch ] )


    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'QR',
            render: ( e ) => {
                return <>
                    { e.id } <QrcodeOutlined
                    onClick={ () => {
                        const url = `${ Connections.server_url }${ e.qr_pdf }`

                        dispatch( printQr( { id: e.unique_id } ) )
                        window.open( url, "_blank" )
                    } }
                    style={ { cursor: "pointer", marginLeft: "20px" } }/>
                </>
            }
        },
        {
            key: "3",
            title: 'Type',
            dataIndex: 'qr_type'
        },
        {
            key: "4",
            title: 'Action',
            render: ( e ) => {
                return <>
                    {
                        e.inTrash ? <HistoryOutlined
                            style={ { cursor: "pointer", color: "red", margin: "15px" } }
                            onClick={ () => {
                                console.log( "Roll back" )
                            } }
                        /> : null
                    }
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "qr",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    const onDragEnd = ( { active, over } ) => {
        if ( active.id !== over?.id ) {
            const activeIndex = qrs.findIndex( ( i ) => i.id === active.id );
            const overIndex = qrs.findIndex( ( i ) => i.id === over?.id );
            dispatch( setStudentsDataSource( arrayMove( qrs, activeIndex, overIndex ) ) )
        }
    };

    return <div style={ { width: "100%" } }>
        <Button
            onClick={ () => dispatch( setModalType( "generateQR" ) ) }
            style={ { marginBottom: "20px", height: "55px", width: "120px" } }
            type="primary"
            size="small"
        >Generate <QrcodeOutlined/></Button>

        <DndContext modifiers={ [ restrictToVerticalAxis ] } onDragEnd={ onDragEnd }>
            <SortableContext
                items={ qrs?.map( ( i ) => i.id ) }
                strategy={ verticalListSortingStrategy }
            >
                <Table
                    components={ {
                        body: {
                            row: Row,
                        },
                    } }
                    expandable={ {
                        expandedRowRender: ( record ) => {
                            return <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ [ { ...record } ] }
                                pagination={ false }
                            />;
                        },
                    } }
                    rowSelection={ {
                        type: "checkbox",
                        ...rowSelection,
                    } }
                    rowKey="id"
                    columns={ columns }
                    dataSource={ qrs }
                />
            </SortableContext>
        </DndContext>
    </div>;
};
export default Qr;