import React, { useEffect } from 'react';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Image, Switch, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Row, rowSelection } from "../Common/tableUtils"
import { v4 as uuidV4 } from "uuid";
import { changePermission, getPermissions } from "../../Redux/Slices/Permissions/asyncThunks";
import Connections from "../../Services/connections";


const expandableColumns = [
    {
        key: "6",
        title: 'Classroom',
        dataIndex: 'classroom',
    },
];

const Permissions = () => {
    const dispatch = useDispatch()
    const permissions = useSelector( state => state.permissions.all )
    const user = useSelector( state => state.authentication.user )
    const role = user.role
    console.log(user, "<><><><><><><><><>< USER")

    useEffect( () => {
        if ( !permissions.length ) {
            dispatch( getPermissions() )
        }
    }, [ dispatch ] )

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id"
        },
        {
            key: "2",
            title: 'Photo',
            dataIndex: 'photo',
            render: ( photo ) => {
                return <Image
                    src={ Connections.GetImage( photo || "default_user.png" ) }
                    width="35px"
                    height="35px"
                    alt="avatar"
                    style={ { width: '100%' } }
                />
            }
        },
        {
            key: "3",
            title: 'Name',
            dataIndex: 'name'
        },
        {
            key: "4",
            title: 'Lastname',
            dataIndex: "lastName"
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <SortableContext
            items={ permissions?.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        console.log( record.password.permission )
                        const data = record.password.permission
                        const permissions = []
                        for ( let prop in data ) {
                            if ( prop !== "id" && prop !== "passed_test" ){
                                permissions.push( {
                                    userId: record.id,
                                    name: prop,
                                    label: prop.split( "_" ).join( " " ).toString( " " ),
                                    value: data[ prop ]
                                } )
                            }
                        }
                        return <div key={ uuidV4() }
                                    style={ { width: "100%", display: "flex", flexWrap: "wrap", gap: "35px" } }>
                            {
                                permissions.map( permission => {
                                    console.log(permission, role)
                                    if ( role !== "SuperAdmin" && (
                                        permission.name === "manage_trash" || permission.name === "manage_payment" ||
                                        permission.name === "stopped" || permission.name === "inTrash"
                                    ) ) {
                                        return <></>
                                    }
                                    return <div
                                        key={ uuidV4() }
                                        style={ {
                                            width: "65px",
                                            height: "85px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "column",
                                        } }
                                    >
                                        <h5 style={ { textAlign: "center" } }>{ permission?.label }</h5>
                                        <Switch
                                            defaultChecked={ permission.value }
                                            onChange={ (value) => {
                                                dispatch( changePermission( {
                                                    userId: permission.userId,
                                                    prop: permission.name,
                                                    value
                                                } ) )
                                            } }
                                        />
                                    </div>
                                } )
                            }
                        </div>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ permissions }
            />
        </SortableContext>
    </div>
};
export default Permissions;