import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Select, InputNumber, Upload } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import { v4 as uuidV4 } from "uuid"
import { addLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import {
    globalBeforeUpload,
    globalGetBase64,
    GlobalFormItem,
    GlobalUploadButton
} from "../../Common/globalFormItems";
import { dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input


export const AddLesson = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const categories = useSelector( state => state.lessons.categories )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ uploadedPhoto, setUploadedPhoto ] = useState( { url: "", file: null } );

    const onFinish = ( value ) => {
        let data = {}
        for ( let prop in value ) {
            if ( value[ prop ] && prop !== "icon" && prop !== "photo" ) {
                data[ prop ] = value[ prop ]
            }
            if ( prop === "icon" ) {
                data.icon = value[ prop ]?.file?.originFileObj
            }

            if ( prop === "photo" ) {
                data.photo = value[ prop ]?.file?.originFileObj
            }

            if ( prop === "categoriesIds" ) {
                data.categoriesIds = value[ prop ].map( category => category.value ).toString()
            }

        }
        data.language = selected
        dispatch( addLesson( data ) )
        dispatch( setModalType( null ) )
    };


    const categoriesOption = categories.map( category => {
        return {
            key: uuidV4(),
            label: category.title[ selected ],
            value: `${ category.id }`
        }
    } )


    const handleChange = ( info ) => {
        if ( info.file ) {
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const handleChangePhoto = ( info ) => {
        if ( info.file ) {
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedPhoto( { url, file: info.file.originFileObj } );
            } );
        }
    };

    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `New Lesson: ${ selected.toUpperCase() }` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }>

            <GlobalFormItem name="categoriesIds" label="Categories">
                <Select
                    mode="multiple"
                    labelInValue
                    optionFilterProp="label"
                    style={ {
                        position: "relative",
                        margin: "0 0 25px 0",
                        width: '100%',
                    } }
                    placeholder="Filters"
                    options={ categoriesOption }
                />
            </GlobalFormItem>

            <GlobalFormItem name="name" label="Lesson">
                <Input placeholder="Lesson name" required/>
            </GlobalFormItem>

            <GlobalFormItem name="heading" label="Heading">
                <Input placeholder="Lesson heading" required/>
            </GlobalFormItem>

            <GlobalFormItem name="title" label="Title">
                <Input placeholder="Lesson title" required/>
            </GlobalFormItem>

            <GlobalFormItem name="description" label="Description">
                <TextArea rows={ 3 } placeholder="Lesson description" required/>
            </GlobalFormItem>

            <GlobalFormItem name="icon" label="Icon">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    customRequest={ dummyRequest }
                    accept="image/*"
                    onChange={ handleChange }
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url ?
                        <img src={ uploadedImage.url } alt="avatar" style={ { width: '100%' } }/> :
                        <GlobalUploadButton loading={ loading }/>
                    }
                </Upload>
            </GlobalFormItem>

            <GlobalFormItem name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChangePhoto }
                    style={ { width: '355px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedPhoto.url ?
                        <img src={ uploadedPhoto.url } alt="avatar" style={ { width: '100%' } }/> :
                        <GlobalUploadButton/> }
                </Upload>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <GlobalFormItem name="duration" label="Duration">
                    <InputNumber
                        max={ 30 }
                        maxLength={ 2 }
                        style={ { width: "100%" } }
                        placeholder="1"
                        required
                    />
                </GlobalFormItem>
                <GlobalFormItem name="durationPrefix" label=" ">
                    <Select
                        // defaultValue="lucy"
                        placeholder="1 Month"
                        // onChange={ handleChange }
                        style={ {
                            maxWidth: "140px"
                        } }
                        options={ [
                            { value: 'd', label: 'Days' },
                            { value: 'w', label: 'Week' },
                            { value: 'm', label: 'Month' },
                            { value: 'y', label: 'Year' },
                        ] }
                        required
                    />
                </GlobalFormItem>
            </div>


            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <GlobalFormItem name="price" label="Price">
                    <InputNumber style={ { width: "100%" } } placeholder="Lesson price" required/>
                </GlobalFormItem>
                <GlobalFormItem isRequired={ false } name="" label=" ">
                    <Select
                        disabled
                        style={ {
                            position: "relative",
                            // margin: "25px 0",
                            width: '80px',
                        } }
                        placeholder={ selected === "en" ? "USD" : "AMD" }
                    />
                </GlobalFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    </Card>
}
