import React, { useEffect } from 'react';
import style from './styles/exercises.module.scss';
import {
    DeleteOutlined,
    EditOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import { v4 as uuidV4 } from "uuid"
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Badge, Button, Image, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../../Redux/Slices/modal";
import { Row, rowSelection } from "../../Common/tableUtils"
import Connections from "../../../Services/connections";
import { getExercises } from "../../../Redux/Slices/Education/Exercises/asyncThunk";

const Exercises = () => {
    const dispatch = useDispatch()
    const selectedLanguage = useSelector( state => state.languages.selected )
    const exercises = useSelector( state => state.exercises.all )


    useEffect( () => {
        dispatch( getExercises() )
    }, [ dispatch ] )

    const expandableColumns = [
        {
            key: "photo",
            title: "Photo",
            dataIndex: "photo",
            render: ( photo ) => {
                const images = photo.split( "," )
                return <div style={ { display: "flex", flexWrap: "wrap", gap: "15px" } }>
                    {
                        images.map( image => <div
                            key={ uuidV4() }
                            style={ { margin: "5xp" } }
                        >
                            <Image
                                src={ Connections.GetImage( image ) }
                                width="35px"
                                height="35px"
                                alt="lesson"
                            />
                        </div> )
                    }
                </div>
            }
        },
        {
            key: "lesson",
            title: "Lesson",
            render: ( record ) => {
                return record.sub_lesson.lesson.name || <b><i>Empty</i></b>
            }
        },
        {
            key: "lesson-title",
            title: 'Lesson title',
            render: ( data ) => {
                return data?.sub_lesson?.title[ selectedLanguage ] || <b><i>Empty</i></b>
            }
        },

    ];

    const columns = [
        {
            key: "1",
            title: "ID",
            dataIndex: "id"
        },
        {
            key: "2",
            title: "In Live",
            render: ( { can_show } ) => {
                return <div style={ { maxWidth: "55px", width: "100%", display: "flex", justifyContent: "center" } }>
                    <Badge status={ can_show ? "success" : "error" }/>
                </div>
            }
        },
        {
            key: "icon",
            title: "Icon",
            render: ( data ) => {
                const icon = data?.sub_lesson?.lesson?.icon
                return icon ? <Image
                    src={ Connections.GetImage( icon ) }
                    width="35px"
                    height="35px"
                    alt="avatar"
                /> : <b><i>Empty</i></b>
            }
        },

        {
            key: "4",
            title: "Title",
            dataIndex: "title",
            render: ( record ) => {
                return record[ selectedLanguage ] || <b><i>Empty</i></b>
            }
        },
        {
            key: "5",
            title: "Descriptions",
            dataIndex: "description",
            render: ( record ) => {
                return record[ selectedLanguage ] || <b><i>Empty</i></b>
            }
        },
        {
            key: "7",
            title: "Action",
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editExercise",
                                transfer_id: e.id,
                                process_type: "exercise",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "exercise",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div className={style.contentContainer}>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addExercise" ) )
                } }
        >Add Exercise <ProfileOutlined/> </Button>
        {/*<LanguagesHeader/>*/ }
        <SortableContext
            items={ exercises.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <>
                            <Table
                                rowKey="id"
                                columns={ expandableColumns }
                                dataSource={ [ record ] || [] }
                                pagination={ false }
                            />
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ exercises }
            />
        </SortableContext>
    </div>
};
export default Exercises;