import { createSlice } from "@reduxjs/toolkit";
import { getUserData, signUp } from "./asyncThunk";



export const authentication = createSlice( {
    name: "authentication",
    initialState: {
        user: {
            id: "",
            name: "",
            lastName: "",
            photo: "",
            phone: "",
        },
        permissions: null
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( signUp.fulfilled, ( state, action ) => {

            } );
        builder
            .addCase( getUserData.fulfilled, ( state, action ) => {
                state.user = action.payload.user;
                state.permissions = action.payload.permissions;
            } )
            .addCase( getUserData.rejected, ( state, action ) => {
                state.user = {
                    id: "",
                    name: "",
                    lastName: "",
                    photo: "",
                    phone: "",
                };
                state.permissions = null;
            } );
    }
} )

// export const {} = authentication.actions
export default authentication.reducer