import React, { useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined, FileAddOutlined, FileExcelOutlined, FileSyncOutlined,
    ProfileOutlined,
} from '@ant-design/icons';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Image, Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalTransferData, setModalType } from "../../Redux/Slices/modal";
import LanguagesHeader from "../Common/languages";
import { getLessons } from "../../Redux/Slices/Education/Lessons/asyncThunks";
import { getLessonsCategories } from "../../Redux/Slices/Education/Lessons/asyncThunksCategory";
import { Row, rowSelection } from "../Common/tableUtils"
import Connection from "../../Services/connections";


const handlerShowedTextPreview = ( text, maxLength = 35 ) => {
    if ( text.length > maxLength ) {
        return `${ text.substr( 0, maxLength ) }...`
    }
    return text
}


const Lessons = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const lessons = useSelector( state => state.lessons.all )

    useEffect( () => {
        dispatch( getLessons() )
        dispatch( getLessonsCategories() )
    }, [ dispatch ] )

    const expandableColumns = [
        {
            key: "6",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "7",
            title: 'Title',
            dataIndex: "title",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        {
            key: "8",
            title: 'Description',
            dataIndex: "description",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        {
            key: "9",
            title: 'Actions',
            render: ( record ) => {
                return <>
                    <FileSyncOutlined
                        style={ { fontSize: "20px", cursor: "pointer" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editSubLesson",
                                transfer_id: record.lessonId,
                                process_type: `${ record.id }`,
                            } ) )
                        } }
                    />
                    <FileExcelOutlined
                        style={ { margin: "10px 0 0 10px", fontSize: "20px", cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: record.id,
                                process_type: "subLessons",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];

    const categoriesColumns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id",
        },
        {
            key: "2",
            title: 'Title',
            dataIndex: "title",
            render: ( data ) => {
                return <p>{ data[ selected ] || "empty" }</p>
            }
        },
        // {
        //     key: "3",
        //     title: 'Description',
        //     dataIndex: "description",
        //     render: ( data ) => {
        //         return <p>{ data[ selected ] || "empty" }</p>
        //     }
        // },
    ]

    const columns = [
        {
            key: "1",
            title: 'ID',
            dataIndex: "id",
            width: "55px"
        },
        {
            key: "2",
            title: 'Name',
            dataIndex: "name"
        },
        {
            key: "3",
            title: 'Icon',
            render: ( data ) => {
                return <Image
                    src={ Connection.GetImage( data.icon ) }
                    width="50px"
                    height="50px"
                    alt="avatar"
                    style={ { width: '100%' } }
                />
            }
        },
        {
            key: "4",
            title: 'Title',
            render: ( data ) => {
                return <p>{ handlerShowedTextPreview( data?.title[ selected ] ) || "Empty" }</p>
            }
        },
        {
            key: "5",
            title: 'Heading',
            render: ( data ) => {
                return <p>{ handlerShowedTextPreview( data?.heading[ selected ] ) || "Empty" }</p>
            }
        },
        {
            key: "6",
            title: 'Description',
            render: ( data ) => {
                return <p
                    style={ {
                        maxWidth: "200px",
                        textAlign: "start"
                    } }>{ handlerShowedTextPreview( data?.description[ selected ] ) || "Empty" }</p>
            }
        },
        {
            key: "7",
            title: 'Photo',
            render: ( data ) => {
                return <Image
                    src={ Connection.GetImage( data.photo ) }
                    width="65px"
                    height="40px"
                    alt={ data.name }
                    style={ { width: '100%' } }
                />
            }
        },
        {
            key: "8",
            title: 'Duration',
            render: ( data ) => {
                return <p
                    style={ { textAlign: "justify" } }>{ `${ data?.duration } ${ data.durationPrefix }` || "Empty" }</p>
            }
        },
        {
            key: "9",
            title: 'Price',
            render: ( data ) => {
                return <p style={ { textAlign: "justify" } }>{ data?.price[ selected ] || "Empty" }</p>
            }
        },
        {
            key: "10",
            title: 'Action',
            render: ( e ) => {
                return <>
                    <EditOutlined
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "editLesson",
                                transfer_id: e.id,
                                process_type: "lessons",
                            } ) )
                        } } style={ { cursor: "pointer", marginRight: "20px" } }
                    />
                    <DeleteOutlined
                        style={ { cursor: "pointer", color: "red" } }
                        onClick={ () => {
                            dispatch( setModalTransferData( {
                                modalType: "delete",
                                transfer_id: e.id,
                                process_type: "lessons",
                            } ) )
                        } }
                    />
                </>
            }
        },
    ];


    return <div style={ { width: "100%", minHeight: "100vh" } }>
        <Button type="primary"
                onClick={ () => {
                    dispatch( setModalType( "addLesson" ) )
                } }
        >Add Lesson <ProfileOutlined/> </Button>
        <LanguagesHeader/>
        <SortableContext
            items={ lessons.map( ( i ) => i.id ) }
            strategy={ verticalListSortingStrategy }
        >
            <Table
                components={ {
                    body: {
                        row: Row,
                    },
                } }
                expandable={ {
                    expandedRowRender: ( record ) => {
                        return <>
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px 0"
                            } }>
                                <h2 style={ {
                                    textAlign: "center",
                                    marginBottom: "15px",
                                    color: "#70c28a"
                                } }>Categories</h2>
                                <Table

                                    rowKey="id"
                                    columns={ categoriesColumns }
                                    dataSource={ record.categories || [] }
                                    pagination={ false }
                                    style={ { marginBottom: "15px" } }
                                />
                            </div>
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                margin: "15px 0"
                            } }>
                                <h2 style={ { textAlign: "center", marginBottom: "15px", color: "#70c28a" } }>Sub
                                    lessons</h2>
                                <Table
                                    rowKey="id"
                                    columns={ expandableColumns }
                                    dataSource={ record.sub_lessons || [] }
                                    pagination={ false }
                                />
                            </div>
                            <div style={ {
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                margin: "15px 0"
                            } }>
                                <FileAddOutlined
                                    onClick={ () => {
                                        dispatch( setModalTransferData( {
                                            modalType: "addSubLesson",
                                            transfer_id: record.id,
                                            process_type: "addSubLesson",
                                        } ) )
                                    } }
                                    style={ { cursor: "pointer", fontSize: "30px" } }
                                />
                            </div>
                        </>
                    },
                } }
                rowSelection={ {
                    type: "checkbox",
                    ...rowSelection,
                } }
                rowKey="id"
                columns={ columns }
                dataSource={ lessons }
            />
        </SortableContext>
    </div>
};
export default Lessons;