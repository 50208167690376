import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, InputNumber, Select, Upload } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import Connection from "../../../Services/connections"
import { editLesson } from "../../../Redux/Slices/Education/Lessons/asyncThunks";
import { v4 as uuidV4 } from "uuid";
import {
    globalBeforeUpload,
    globalGetBase64,
    GlobalFormItem,
    GlobalUploadButton
} from "../../Common/globalFormItems";
import { dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input

export const EditLesson = () => {
    const dispatch = useDispatch()
    const selected = useSelector( state => state.languages.selected )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const lessons = useSelector( state => state.lessons.all )
    const selectedLesson = lessons.filter( lesson => lesson.id === transfer_id )[ 0 ]
    const categories = useSelector( state => state.lessons.categories )


    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );
    const [ uploadedPhoto, setUploadedPhoto ] = useState( { url: "", file: null } );

    const onFinish = ( value ) => {

        let data = {}

        for ( let prop in value ) {
            if ( value[ prop ] && value[ prop ] !== 0 ) {
                if (
                    value[ prop ] && value[ prop ] !== selectedLesson[ prop ]
                    && prop !== "icon" && prop !== "photo" && prop !== "title"
                    && prop !== "description" && prop !== "heading" && prop !== "price" && prop !== "categoriesIds"
                ) {
                    data[ prop ] = value[ prop ]
                }
                if ( prop === "icon" ) {
                    data.icon = value[ prop ]?.file?.originFileObj
                }
                if ( prop === "photo" ) {
                    data.photo = value[ prop ]?.file?.originFileObj
                }
                if ( ( prop === "title" || prop === "description" || prop === "heading" )
                    && value[ prop ] !== selectedLesson[ prop ][ selected ] ) {
                    data[ prop ] = value[ prop ]
                }
                if ( prop === "price" && value.price !== selectedLesson?.price[ selected ]?.substring(
                    0, selectedLesson?.price[ selected ].indexOf( selected === "en" ? "USD" : "AMD" )
                ) ) {
                    data[ prop ] = `${ value[ prop ] } ${ selected === "en" ? "USD" : "AMD" }`
                }

                if ( prop === "categoriesIds" ) {
                    data.categoriesIds = value[ prop ].map( category => category.value ).toString()
                }

            }
        }
        if ( Object.keys( data ).length ) {
            data.id = transfer_id
            data.language = selected
            dispatch( editLesson( data ) )
        }
        dispatch( setModalType( null ) )
    };

    const categoriesOption = categories.map( category => {
        return {
            key: uuidV4(),
            label: category.title[ selected ],
            value: `${ category.id }`
        }
    } )

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };

    const handleChangePhoto = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedPhoto( { url, file: info.file.originFileObj } );
            } );
        }
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `Edit: ${ selectedLesson.name } Lesson || ${ selected.toUpperCase() } ` }
    >
        <Form
            name="form_item_path" layout="vertical" onFinish={ onFinish }
            initialValues={ {
                "name": selectedLesson?.name,
                "heading": selectedLesson?.heading[ selected ],
                "title": selectedLesson?.title[ selected ],
                "description": selectedLesson?.description[ selected ],
                "duration": selectedLesson?.duration,
                "durationPrefix": selectedLesson?.durationPrefix,
                "price": selectedLesson?.price[ selected ]?.substring(
                    0, selectedLesson?.price[ selected ].indexOf( selected === "en" ? "USD" : "AMD" )
                ) || 0,
            } }
        >

            <GlobalFormItem isRequired={ !selectedLesson.categories.length } name="categoriesIds" label="Categories">
                <Select
                    mode="tags"
                    labelInValue
                    allowClear
                    style={ {
                        position: "relative",
                        margin: "0 0 25px 0",
                        width: '100%',
                    } }
                    defaultValue={ selectedLesson?.categories?.map( category => {
                        return {
                            key: uuidV4(),
                            label: category.title[ selected ],
                            value: `${ category.id }`
                        }
                    } )
                    }
                    placeholder="Filters"
                    options={ categoriesOption }
                />
            </GlobalFormItem>

            <GlobalFormItem name="name" label="Lesson">
                <Input placeholder="Lesson name"/>
            </GlobalFormItem>

            <GlobalFormItem name="heading" label="Heading">
                <Input/>
            </GlobalFormItem>

            <GlobalFormItem name="title" label="Title">
                <Input/>
            </GlobalFormItem>

            <GlobalFormItem name="description" label="Description">
                <TextArea rows={ 10 } placeholder="Lesson description" required/>
            </GlobalFormItem>

            <GlobalFormItem isRequired={ !selectedLesson.icon } name="icon" label="Icon">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    customRequest={ dummyRequest }
                    onChange={ handleChange }
                    accept="image/*"
                    style={ { width: '300px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedImage.url || Connection.GetImage( selectedLesson.icon ) ?
                        <img src={ uploadedImage.url || Connection.GetImage( selectedLesson.icon ) } alt="avatar"
                             style={ { width: '100%' } }/> : <GlobalUploadButton loading={ loading }/> }
                </Upload>
            </GlobalFormItem>
            <GlobalFormItem isRequired={ !selectedLesson.photo } name="photo" label="Photo">
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={ false }
                    accept="image/*"
                    customRequest={ dummyRequest }
                    onChange={ handleChangePhoto }
                    style={ { width: '355px' } }
                    beforeUpload={ globalBeforeUpload }
                >
                    { uploadedPhoto.url || Connection.GetImage( selectedLesson.photo ) ?
                        <img src={ uploadedPhoto.url || Connection.GetImage( selectedLesson.photo ) } alt="avatar"
                             style={ { width: '100%' } }/> : <GlobalUploadButton/> }
                </Upload>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <GlobalFormItem name="duration" label="Duration">
                    <InputNumber
                        max={ 30 }
                        maxLength={ 2 }
                        style={ { width: "100%" } }
                        placeholder="1"
                        required
                    />
                </GlobalFormItem>
                <GlobalFormItem isRequired={ false } name="durationPrefix" label="Prefix">
                    <Select
                        // defaultValue="lucy"
                        placeholder="1 Month"
                        // onChange={ handleChange }
                        style={ {
                            maxWidth: "140px"
                        } }
                        options={ [
                            { value: 'd', label: 'Days' },
                            { value: 'w', label: 'Week' },
                            { value: 'm', label: 'Month' },
                            { value: 'y', label: 'Year' },
                        ] }
                        required
                    />
                </GlobalFormItem>
            </div>


            <div style={ { width: "100%", display: "flex", justifyContent: "Space-between" } }>
                <GlobalFormItem name="price" label="Price">
                    <InputNumber style={ { width: "100%" } } placeholder="Lesson price" required/>
                </GlobalFormItem>
                <GlobalFormItem isRequired={ false } name="" label=" ">
                    <Select
                        disabled
                        style={ {
                            position: "relative",
                            // margin: "25px 0",
                            width: '80px',
                        } }
                        placeholder={ selected === "en" ? "USD" : "AMD" }
                    />
                </GlobalFormItem>
            </div>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Edit
                </Button>
            </div>
        </Form>
    </Card>
}