import { notification } from "antd";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../fetch";
import Connection from "../../../Services/connections";
import { setLoadingState } from "../loading";
import Connections from "../../../Services/connections";
import dataControl from "../../../Services/dataControl";
import { handlerFormData } from "../../../Components/TimeLine/components/utils";

export const getUserData = createAsyncThunk(
    "authentication/getUserData",
    async ( navigate, action ) => {
        action.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connections.UserData()
            )

            action.dispatch( setLoadingState( false ) )
            return response.data.info
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            action.dispatch( setLoadingState( false ) )
            // return {};
        }
    }
)

export const changeUserInfo = createAsyncThunk(
    'authentication/changeUserInfo',
    async ( { data, image, navigation }, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data )
            if ( image ) {
                formData.append( "file", image )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.ChangeInfo(),
                formData
            );
            if ( response.data.message === "success" ) {
                // return actions.dispatch( getExams() )
                actions.dispatch( setLoadingState( false ) )
                actions.dispatch( getUserData( navigation ) )
                return response.data.user;
            }
            actions.dispatch( setLoadingState( false ) )
        } catch ( e ) {
            actions.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
        }
    }
);

export const signUp = createAsyncThunk(
    "authentication/signUp",
    async ( payload, action ) => {
        try {
            action.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.SignUp(),
                {
                    ...payload.account,
                }
            )
            dataControl.saveToken( response.data.access_token )
            payload.navigate( '/' )
        } catch ( e ) {
            action.dispatch( setLoadingState( false ) )
            notification.error( {
                placement: 'topRight',
                message: "Check form entries",
            } );
        }
    }
)


export const checkAccount = createAsyncThunk(
    "authentication/checkingAccount",
    async ( navigate, action ) => {
        action.dispatch( setLoadingState( true ) )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connections.CheckAccount()
            )

            if ( response.data.authenticate ) {
                navigate( '/' )
            } else {
                navigate( '/auth' )
                dataControl.removeToken()
            }
            action.dispatch( setLoadingState( false ) )
        } catch ( e ) {
            navigate( '/auth' )
            dataControl.removeToken()
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
            action.dispatch( setLoadingState( false ) )
        }
    }
)


export const resetUserPassword = createAsyncThunk(
    "authentication/resetUserPassword",
    async ( { data, navigate }, action ) => {
        action.dispatch( setLoadingState( true ) )
        const formData = handlerFormData( data )
        try {
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connections.resetUserPassword(),
                formData
            )

            if ( response.data.authenticate ) {
                navigate( '/' )
            } else {
                navigate( '/auth' )
                dataControl.removeToken()
            }
            action.dispatch( setLoadingState( false ) )
        } catch ( e ) {
            navigate( '/auth' )
            dataControl.removeToken()
            notification.error( {
                placement: 'topRight',
                message: e.message,
            } );
            action.dispatch( setLoadingState( false ) )
        }
    }
)
