import { configureStore } from '@reduxjs/toolkit'
import authentication  from "./Slices/Auth/auth";
import languages from "./Slices/languages";
import qr from "./Slices/Education/QR/qr";
import students from "./Slices/Education/Students";
import main from "./Slices/main";
import admins from "./Slices/Admins";
import modal from "./Slices/modal";
import loading from "./Slices/loading";
import content from "./Slices/Content/content";
import contentPackages from "./Slices/ContentPackages/contentPackages";
import teachers from "./Slices/Education/Teachers/teachers";
import exams from "./Slices/Education/Exams/exams";
import groups from "./Slices/Education/Group/group";
import packages from "./Slices/Education/Packages/packages";
import lessons from "./Slices/Education/Lessons/lessons";
import timeLines from "./Slices/Education/TimeLine/timeLine";
import exercises from "./Slices/Education/Exercises/exercises";
import customers from "./Slices/Customer";
import homeworks from "./Slices/Education/Homeworks";
import permissions from "./Slices/Permissions";
import emailTypes from "./Slices/Education/Emails/email";
import examResults from "./Slices/Education/ExamResults";
import payments from "./Slices/Education/Payment";


const store = configureStore( {
    reducer: {
        qr,
        main,
        modal,
        exams,
        groups,
        admins,
        loading,
        content,
        lessons,
        teachers,
        packages,
        payments,
        students,
        exercises,
        homeworks,
        timeLines,
        languages,
        customers,
        emailTypes,
        examResults,
        permissions,
        contentPackages,
        authentication,
    }
} )

export default store