import React, { useEffect, useState } from 'react';
import {
    ContainerOutlined,
    DeploymentUnitOutlined,
    FieldTimeOutlined, FileDoneOutlined, GlobalOutlined, HeatMapOutlined, Html5Outlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined, MergeOutlined, NodeExpandOutlined, OrderedListOutlined,
    ProductOutlined,
    ProfileOutlined,
    QrcodeOutlined,
    RadarChartOutlined,
    ReadOutlined, ReconciliationOutlined, SendOutlined, SettingOutlined, SignatureOutlined,
    SolutionOutlined,
    TeamOutlined, TransactionOutlined,
    UserOutlined, UserSwitchOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { checkAccount, getUserData } from "../../Redux/Slices/Auth/asyncThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Students from "../Students";
import ContentControl from "../Content";
import Qr from "../QR";
import { setModalType } from "../../Redux/Slices/modal";
import dataControl from "../../Services/dataControl";
import Exams from "../Exam";
import Teachers from "../Teachers";
import Groups from "../Groups";
import Packages from "../Packages";
import Lessons from "../Lessons";
import TimeLines from '../TimeLine';
import Loading from "../Loading";
import ContentPackages from "../ContentPackages";
import Customers from "../Customer";
import LessonsCategories from "../LessonsCategories";
import SendTypeEmail from "../Emails";
import UserInfo from "../User";
import Admins from "../Admins";
import Exercises from "../Education/Exercises";
import Permissions from "../Permissions";
import Homeworks from "../Education/Homeworks";
import Payments from "../Education/Payments";
import ExamResults from "../Education/ExamResults";

const { Header, Sider, Content } = Layout;

const menuItems = ( { permissions, selectItemOfMenu, dispatch, role } ) => {
    return [
        //Check keys
        {
            key: '1212',
            icon: <UserOutlined/>,
            label: 'User',
            onClick: () => {
                if ( !permissions?.manage_admins ) {
                    selectItemOfMenu( () => {
                        const name = "userInfo"
                        dataControl.saveSelectedMenuItem( { name, key: 12125 } )
                        return name
                    } )
                }
            },
            children: !permissions?.manage_admins ? null : [
                {
                    key: '12125',
                    icon: <SettingOutlined/>,
                    label: 'User info',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "userInfo"
                            dataControl.saveSelectedMenuItem( { name, key: 12125 } )
                            return name
                        } )
                    }
                },
                {
                    //TODO check permissions
                    key: '12126',
                    icon: <TeamOutlined/>,
                    label: 'Admins',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "Admins"
                            dataControl.saveSelectedMenuItem( { name, key: 12126 } )
                            return name
                        } )
                    }
                },
                {
                    //TODO check permissions
                    key: '121266',
                    icon: <NodeExpandOutlined/>,
                    label: 'Permissions',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "Permissions"
                            dataControl.saveSelectedMenuItem( { name, key: 121266 } )
                            return name
                        } )
                    }
                },
            ]
        },
        permissions?.manage_customers ? {
            key: '1',
            icon: <UserSwitchOutlined/>,
            label: 'Customers',
            onClick: () => {
                selectItemOfMenu( () => {
                    const name = "Customers"
                    dataControl.saveSelectedMenuItem( { name, key: 1 } )
                    return name
                } )
            }
        } : null,
        permissions?.manage_content ? {
            key: '2',
            icon: <ProfileOutlined/>,
            label: 'Content',
            children: [
                {
                    key: '21',
                    icon: <GlobalOutlined/>,
                    label: 'Main page',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "page: main"
                            dataControl.saveSelectedMenuItem( { name, key: 21 } )
                            return name
                        } )
                    }
                },
                {
                    key: '22',
                    icon: <HeatMapOutlined/>,
                    label: 'Company page',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "page: company"
                            dataControl.saveSelectedMenuItem( { name, key: 22 } )
                            return name
                        } )
                    }
                },
                {
                    key: '23',
                    icon: <RadarChartOutlined/>,
                    label: 'Services page',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "page: services"
                            dataControl.saveSelectedMenuItem( { name, key: 23 } )
                            return name
                        } )
                    }
                },
                {
                    key: '24',
                    icon: <Html5Outlined/>,
                    label: 'Courses page',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "page: courses"
                            dataControl.saveSelectedMenuItem( { name, key: 24 } )
                            return name
                        } )
                    }
                },
            ]
        } : null,
        permissions?.manage_contents ? {
            key: '3',
            icon: <OrderedListOutlined/>,
            label: 'Content Packages',
            onClick: () => {
                selectItemOfMenu( () => {
                    const name = "Content Packages"
                    dataControl.saveSelectedMenuItem( { name, key: 3 } )
                    return name
                } )
            }
        } : null,
        permissions?.manage_education ? {
            key: '4',
            icon: <ReconciliationOutlined/>,
            label: 'Education',
            children: [
                permissions?.manage_qrs ? {
                    key: '11',
                    icon: <QrcodeOutlined/>,
                    label: 'QRS',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "qrs"
                            dataControl.saveSelectedMenuItem( { name, key: 11 } )
                            return name
                        } )
                    }
                } : null,
                permissions.manage_students ? {
                    key: 'learners',
                    icon: <UserOutlined/>,
                    label: 'Learners',
                    children: [
                        {
                            key: 'learners-students',
                            icon: <UserOutlined/>,
                            label: 'Students',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "students"
                                    dataControl.saveSelectedMenuItem( {
                                        name,
                                        key: "learners-students"
                                    } )
                                    return name
                                } )
                            },
                        },
                        permissions?.manage_payments && role === "SuperAdmin" ? {
                            key: 'learners-payments',
                            icon: <TransactionOutlined/>,
                            label: 'Payments',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "payments"
                                    dataControl.saveSelectedMenuItem( {
                                        name,
                                        key: "learners-payments"
                                    } )
                                    return name
                                } )
                            },
                        } : null,
                        {
                            key: 'learners-exercises',
                            icon: <SignatureOutlined/>,
                            label: 'Exercises',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "Exercises"
                                    dataControl.saveSelectedMenuItem( {
                                        name,
                                        key: "learners-exercises"
                                    } )
                                    return name
                                } )
                            },
                        },
                        {
                            key: 'learners-homeworks',
                            icon: <ContainerOutlined/>,
                            label: 'Homeworks',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "Homeworks"
                                    dataControl.saveSelectedMenuItem( {
                                        name,
                                        key: "learners-homeworks"
                                    } )
                                    return name
                                } )
                            },
                        },
                        {
                            key: 'learners-examResults',
                            icon: <FileDoneOutlined/>,
                            label: 'Exam Results',
                            onClick: () => {
                                selectItemOfMenu( () => {
                                    const name = "Exam Results"
                                    dataControl.saveSelectedMenuItem( {
                                        name,
                                        key: "learners-examResults"
                                    } )
                                    return name
                                } )
                            },
                        }
                    ]
                } : null,
                {
                    key: '13',
                    icon: <TeamOutlined/>,
                    label: 'Teachers',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "teachers"
                            dataControl.saveSelectedMenuItem( { name, key: 13 } )
                            return name
                        } )
                    }
                },
                {
                    key: '14',
                    icon: <FieldTimeOutlined/>,
                    label: 'Time Line',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "Times"
                            dataControl.saveSelectedMenuItem( { name, key: 14 } )
                            return name
                        } )
                    }
                },
                {
                    key: '15',
                    icon: <ReadOutlined/>,
                    label: 'Exams',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "exams"
                            dataControl.saveSelectedMenuItem( { name, key: 15 } )
                            return name
                        } )
                    }
                },
                {
                    key: '16',
                    icon: <DeploymentUnitOutlined/>,
                    label: 'Groups',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "groups"
                            dataControl.saveSelectedMenuItem( { name, key: 16 } )
                            return name
                        } )
                    }
                },
                {
                    key: '17',
                    icon: <ProductOutlined/>,
                    label: 'Packages',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "packages"
                            dataControl.saveSelectedMenuItem( { name, key: 17 } )
                            return name
                        } )
                    }
                },
                {
                    key: '18',
                    icon: <SolutionOutlined/>,
                    label: 'Lessons',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "lessons"
                            dataControl.saveSelectedMenuItem( { name, key: 18 } )
                            return name
                        } )
                    }
                },
                {
                    key: '19',
                    icon: <MergeOutlined style={ { transform: "rotate(-90deg)" } }/>,
                    label: 'Categories Lessons',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "Lessons Categories"
                            dataControl.saveSelectedMenuItem( { name, key: 19 } )
                            return name
                        } )
                    }
                },
                {
                    key: '20',
                    icon: <SendOutlined/>,
                    label: 'Type emails',
                    onClick: () => {
                        selectItemOfMenu( () => {
                            const name = "Send type emails"
                            dataControl.saveSelectedMenuItem( { name, key: 20 } )
                            return name
                        } )
                    }
                }
            ]
        } : null,
        {
            key: '5',
            icon: <LogoutOutlined/>,
            label: 'Logout',
            onClick: () => {
                dispatch( setModalType( "logout" ) )
            }
        },
    ]
}


const menuSections = {
    students: <Students/>,
    userInfo: <UserInfo/>,
    Admins: <Admins/>,
    Permissions: <Permissions/>,
    qrs: <Qr/>,
    teachers: <Teachers/>,
    exams: <Exams/>,
    Exercises: <Exercises/>,
    Homeworks: <Homeworks/>,
    groups: <Groups/>,
    packages: <Packages/>,
    lessons: <Lessons/>,
    Times: <TimeLines/>,
    payments: <Payments/>,
    Customers: <Customers/>,
    "Exam Results": <ExamResults/>,
    "Content Packages": <ContentPackages/>,
    "Lessons Categories": <LessonsCategories/>,
    "Send type emails": <SendTypeEmail/>,
}


const Main = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ collapsed, setCollapsed ] = useState( false );
    const [ selectedMenuItem, selectItemOfMenu ] = useState( dataControl.getSelectedMenuItem()?.name || "Customers" );
    const permissions = useSelector( state => state.authentication?.permissions )
    const user = useSelector( state => state.authentication?.user )
    const role = user?.role

    //TODO check platform in student without group mode

    useEffect( () => {
        dispatch( checkAccount( navigate ) )
        dispatch( getUserData( navigate ) )
    }, [] )

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout
            style={ { width: "100%", minHeight: "100vh" } }
        >
            <Sider trigger={ null } collapsible collapsed={ collapsed }>
                <div style={ { marginBottom: "50px" } } className="demo-logo-vertical"/>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={ [
                        dataControl.getSelectedMenuItem()?.key ?
                            `${ dataControl.getSelectedMenuItem().key }` : '1'
                    ] }
                    items={ menuItems( { permissions, selectItemOfMenu, dispatch, role } ) }
                />
            </Sider>
            <Layout>
                <Header
                    style={ {
                        padding: 0,
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: "space-between"
                    } }
                >
                    <Button
                        type="text"
                        icon={ collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/> }
                        onClick={ () => setCollapsed( !collapsed ) }
                        style={ {
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        } }
                    />
                    <h1>{ selectedMenuItem.toUpperCase() }</h1>
                    <div/>
                </Header>
                <Content
                    style={ {
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    } }
                >
                    {
                        selectedMenuItem.indexOf( "page:" ) >= 0 ?
                            <ContentControl filter={ selectedMenuItem.substring( 6 ) }/> :
                            menuSections[ selectedMenuItem ] || <></>
                    }
                </Content>
            </Layout>
            <Loading/>
        </Layout>
    );
};
export default Main;