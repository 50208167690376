import React, { useLayoutEffect } from 'react';
import styles from "./styles/auth.module.scss"
import { Button, Form, Input } from 'antd';
import { checkAccount, signUp } from "../../Redux/Slices/Auth/asyncThunk";
import { useDispatch } from "react-redux";
import dataControl from "../../Services/dataControl";
import { useNavigate } from "react-router";
import { setLoadingState } from "../../Redux/Slices/loading";
import { GlobalFormItem } from "../Common/globalFormItems";
import { handlerMutableObject } from "../TimeLine/components/utils";

const onFinishFailed = ( errorInfo ) => {
    // console.log( 'Failed:', errorInfo );
};
const Auth = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useLayoutEffect( () => {
        const token = dataControl.getToken()
        if ( token ) {
            dispatch( setLoadingState( true ) )
            dispatch( checkAccount( navigate ) )
        }
    }, [ dispatch, navigate ] )

    const onFinish = ( values ) => {
        const data = handlerMutableObject( values )
        if ( Object.keys( data ).length ) {
            dispatch( signUp( { account: data, navigate } ) )
        }
    };

    return <div className={ styles.main }>
        <div className={ styles.title_wrapper }>
            <h1> Genie Web </h1>
            <h3>Admin Platform</h3>
        </div>
        <Form
            name="basic"
            labelCol={ { span: 8 } }
            wrapperCol={ { span: 16 } }
            style={ { minWidth: 300, maxWidth: 600 } }
            onFinish={ onFinish }
            onFinishFailed={ onFinishFailed }
            autoComplete="off"
        >
            <GlobalFormItem label="Email" name="email">
                <Input type="email" autoFocus={ true }/>
            </GlobalFormItem>

            <GlobalFormItem label="Password" name="password">
                <Input.Password/>
            </GlobalFormItem>

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </div>
        </Form>
    </div>

};
export default Auth;