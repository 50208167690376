import { Button, Card, Radio } from "antd";
import React, { useState } from "react";
import { generateQr } from "../../../Redux/Slices/Education/QR/asyncThunks";

import { useDispatch } from "react-redux";
import { setModalType } from "../../../Redux/Slices/modal";

const optionsWithDisabled = [
    { label: 'Free 3 days', value: 'free 3 days' },
    { label: '2 person', value: '2 person' },
    { label: 'Discount', value: 'discount' },
];

export const GenerateQRModal = () => {
    const dispatch = useDispatch()

    const [ type, selectType ] = useState()

    const onChange = ( { target: { value } } ) => {
        selectType( value );
    };
    return <Card title="What type of QR do you want?"
                 style={ { width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Radio.Group
                options={ optionsWithDisabled }
                onChange={ onChange }
                value={ type }
                optionType="button"
                buttonStyle="solid"
            />
            <Button
                type="primary"
                disabled={!type}
                styles={{maxWidth: "180px"}}
                onClick={() => {
                    dispatch(generateQr({type: type}))
                    dispatch(setModalType(null))
                }}
                style={{marginTop: "30px"}}
            >Generate QR</Button>
        </div>
    </Card>
}
