import { createSlice } from '@reduxjs/toolkit'
import { getExercises } from "./asyncThunk";


export const exercises = createSlice( {
    name: 'exercises',
    initialState: {
        all: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getExercises.fulfilled, (state, action) => {
            state.all = action.payload
        })
    }
} )

// export const {} = exercises.actions
export default exercises.reducer