import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(HttpApi) // Load translations from the locales folder
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next) // Bind with React
    .init({
        lng: 'am',
        fallbackLng: 'am', // Default language
        debug: false,
        ns: ['common', 'translations'],
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to load translation files
        },
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator'], // Adjust detection order if needed
            caches: ['localStorage'], // Save language selection in localStorage
        },
    });