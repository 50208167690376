import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";
import { handlerFormData } from "../../../../Components/TimeLine/components/utils";

export const getGroups = createAsyncThunk(
    'groups/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.EducationGroups(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.groups;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return []
        }
    }
);


export const addGroup = createAsyncThunk(
    'groups/add',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = handlerFormData( data )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.EducationGroups(),
                formData
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }
            return actions.dispatch( getGroups() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getGroups() )
        }
    }
);


export const editGroup = createAsyncThunk(
    'groups/edit',
    async ( data, actions ) => {
        actions.dispatch( setLoadingState( true ) )
        try {
            const formData = handlerFormData( data )
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.EducationGroups(),
                formData
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }
            return actions.dispatch( getGroups() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getGroups() )
        }
    }
);


export const deleteGroup = createAsyncThunk(
    'groups/delete',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.EducationGroups( data.id ),
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
            }
            return actions.dispatch( getGroups() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getGroups() )
        }
    }
);

