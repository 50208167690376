import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchingDataWithAxiosMiddleware } from "../../fetch";
import Connection from "../../../../Services/connections";
import { setLoadingState } from "../../loading";
import { notification } from "antd";
import { handlerFormData } from "../../../../Components/TimeLine/components/utils";

export const getExams = createAsyncThunk(
    'exams/getAll',
    async ( _, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "GET",
                Connection.GetAllExams(),
            );
            actions.dispatch( setLoadingState( false ) )
            return response.data.exams;
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topRight',
                message: errorMessage,
            } );
            actions.dispatch( setLoadingState( false ) )
            return [];
        }
    }
);

export const addExams = createAsyncThunk(
    'exams/add',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [ "icon" ] )
            if ( data.icon ) {
                formData.append( "file", data.icon )
            }
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.PostAddExam(),
                formData
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }
            return actions.dispatch( getExams() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getExams() )
        }
    }
);

export const addQuestion = createAsyncThunk(
    'exams/addQuestion',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data )
            const response = await fetchingDataWithAxiosMiddleware(
                "POST",
                Connection.PostAddQuestion(),
                formData
            )

            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Added successfully",
                } );
            }
            return actions.dispatch( getExams() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getExams() )
        }
    }
);

export const editExams = createAsyncThunk(
    'exams/edit',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const formData = handlerFormData( data, [ "icon" ] )
            if ( data.icon ) {
                formData.append( "file", data.icon )
            }
            formData.append( "process_type", actions.getState().modal.process_type )
            const response = await fetchingDataWithAxiosMiddleware(
                "PUT",
                Connection.PutEditExam(),
                formData
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Edited successfully",
                } );
            }
            return actions.dispatch( getExams() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getExams() )
        }
    }
);

export const deleteExams = createAsyncThunk(
    'exams/delete',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.DeleteExam( data.id ),
            );
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
            }
            return actions.dispatch( getExams() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getExams() )
        }
    }
);

export const deleteQuestion = createAsyncThunk(
    'exams/deleteQuestion',
    async ( data, actions ) => {
        try {
            actions.dispatch( setLoadingState( true ) )
            const response = await fetchingDataWithAxiosMiddleware(
                "DELETE",
                Connection.DeleteQuestion( data.id )
            )
            if ( response.data.message === "success" ) {
                notification.success( {
                    placement: 'topRight',
                    message: "Deleted successfully",
                } );
            }
            return actions.dispatch( getExams() )
        } catch ( e ) {
            const errorMessage = await e.response.data.message || "connection error";
            notification.error( {
                placement: 'topLeft',
                message: errorMessage
            } );
            return actions.dispatch( getExams() )
        }
    }
);