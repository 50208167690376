import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Card, Radio, Upload } from 'antd';
import { setModalType } from "../../../Redux/Slices/modal";
import Connections from "../../../Services/connections";
import { editExams } from "../../../Redux/Slices/Education/Exams/asyncThunk";
import { globalBeforeUpload, GlobalFormItem, globalGetBase64, GlobalUploadButton } from "../../Common/globalFormItems";
import { compareObjects, dummyRequest } from "../../TimeLine/components/utils";

const { TextArea } = Input


//TODO check level and type handlering and form send logic
export const EditExamData = () => {
    const dispatch = useDispatch()
    const __selected_language = useSelector( state => state.languages.selected )

    const [ loading, setLoading ] = useState( false );
    const [ uploadedImage, setUploadedImage ] = useState( { url: "", file: null } );

    const exams = useSelector( state => state.exams.all )
    const transfer_id = useSelector( state => state.modal.transfer_id )
    const process_type = useSelector( state => state.modal.process_type )
    const processed_item_id = useSelector( state => state.modal.processed_item_id )
    const selected_exam = exams.filter( exam => exam.id === transfer_id )[ 0 ]
    const selected_question = selected_exam?.questions?.filter( question => question.id === processed_item_id )[ 0 ]

    const onFinish = ( value ) => {
        const data = compareObjects( value, selected_exam, [ "icon" ] )
        if ( value.icon ) {
            data.icon = value.icon?.file?.originFileObj
        }
        dispatch( editExams( {
            ...data,
            language: __selected_language,
            examId: selected_exam.id,
            questionId: selected_question?.id,
            // answerId: selected_question?.answers[ 0 ]?.id
        } ) )
        dispatch( setModalType( null ) )
    };

    const handleChange = ( info ) => {
        if ( info.file ) {
            // Get this url from response in real world.
            globalGetBase64( info.file.originFileObj, ( url ) => {
                setLoading( false );
                setUploadedImage( { url, file: info.file.originFileObj } );
            } );
        }
    };


    return <Card
        style={ { position: "relative", width: 570, textAlign: "center", display: "flex", flexDirection: "column" } }
        title={ `EDIT Exam language -> ${ __selected_language.toUpperCase() }` }
    >
        <Form
            initialValues={ process_type === "examInfo" ? {
                    "type": selected_exam.type,
                    "name": selected_exam.name,
                    "level": selected_exam.level,
                    "duration": selected_exam.duration,
                    "duration_format": selected_exam.duration_format,
                }
                : {
                    "title": selected_question?.title[ __selected_language ],
                    "question": selected_question?.question[ __selected_language ],
                    "correct_answer": selected_question?.answers[ 0 ]?.correct_answer,
                    "answer_a": selected_question?.answers[ 0 ]?.answer_a[ __selected_language ],
                    "answer_b": selected_question?.answers[ 0 ]?.answer_b[ __selected_language ],
                    "answer_c": selected_question?.answers[ 0 ]?.answer_c[ __selected_language ],
                    "answer_d": selected_question?.answers[ 0 ]?.answer_d[ __selected_language ],
                } }
            name="form_item_path" layout="vertical" onFinish={ onFinish }>
            {
                process_type === "examInfo" ? <>
                    <GlobalFormItem isRequired={ false } name="type" label="Type">
                        <Input placeholder="CSS"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="name" label="Exam">
                        <Input placeholder="JavaScript"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="level" label="Level">
                        <Input placeholder="Easy"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="icon" label="Icon">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={ false }
                            customRequest={ dummyRequest }
                            accept="image/*"
                            onChange={ handleChange }
                            style={ { width: '300px' } }
                            beforeUpload={ globalBeforeUpload }
                        >
                            { uploadedImage.url || selected_exam?.icon ?
                                <img src={ uploadedImage.url || Connections.GetImage( selected_exam.icon ) }
                                     alt="avatar" style={ { width: '100%' } }/> :
                                <GlobalUploadButton loading={ loading }/>
                            }
                        </Upload>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="duration" label="Duration">
                        <Input placeholder="30"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="duration_format" label="Format">
                        <Input placeholder="minutes"/>
                    </GlobalFormItem>
                </> : <></>
            }

            {
                process_type === "examQuestion" ? <>
                    <GlobalFormItem isRequired={ false } name="title" label="title">
                        <Input placeholder="Ex. Question 1"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="question" label="Question">
                        <TextArea rows={ 3 } placeholder="Write your question here."/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } label="Correct Answer" name="correct_answer">
                        <Radio.Group>
                            <Radio.Button value="answer_a">A</Radio.Button>
                            <Radio.Button value="answer_b">B</Radio.Button>
                            <Radio.Button value="answer_c">C</Radio.Button>
                            <Radio.Button value="answer_d">D</Radio.Button>
                        </Radio.Group>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="answer_a" label="Answer_a">
                        <Input placeholder="A) Your answer here"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="answer_b" label="Answer_b">
                        <Input placeholder="B) Your answer here"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="answer_c" label="Answer_c">
                        <Input placeholder="C) Your answer here"/>
                    </GlobalFormItem>
                    <GlobalFormItem isRequired={ false } name="answer_d" label="Answer_d">
                        <Input placeholder="D) Your answer here"/>
                    </GlobalFormItem>
                </> : <></>
            }

            <div style={ { width: "100%", display: "flex", justifyContent: "center" } }>
                <Button
                    type="default"
                    onClick={ () => dispatch( setModalType( null ) ) }
                    style={ { marginRight: "30px" } }
                >Cancel</Button>
                <Button type="primary" htmlType="submit">
                    Change
                </Button>
            </div>
        </Form>
    </Card>
}
