import { createSlice } from '@reduxjs/toolkit'
import { getPermissions } from "./asyncThunks";

export const index = createSlice( {
    name: 'permissions',
    initialState: {
        all: [],
    },
    reducers: {},
    extraReducers: ( builder ) => {
        builder
            .addCase( getPermissions.fulfilled, ( state, action ) => {
                state.all = action.payload;
            } );
    }
} )

// export const { } = index.actions
export default index.reducer